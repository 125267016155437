<template>
  <!-- 高性能的table -->

  <vxe-grid
      class="dsn-table"
      border
      ref="xTable1"
      :data="tableData"
      :loading="loading"
      :height="height"
      show-overflow
      @cell-click="cellClick"
      @cell-dblclick="cellDblclick"
      @checkbox-all="selectAllEvent"
      :highlightCurrentRow="highlightCurrentRow"
      @checkbox-change="selectChangeEvent"
      resizable
      :checkbox-config="{
        reserve:true,
    }"
  >
    <vxe-table-column v-if="highlightCurrentRow" label width="40"></vxe-table-column>
    <slot></slot>
  </vxe-grid>
</template>
<script>
export default {
  name: "DsnVueTable",
  computed: {
    tableData() {
      return this.data==null?[]:this.data;
    }
  },
  props: {
    highlightCurrentRow: {
      type: Boolean,
      default: false
    },
    height: {
      type: [String],
      default: "530px"
    },
    data: {
      type: Array,
      default: () => {
        return [];
      }
    },
    loading: {
      type: Boolean,
      default: false
    },
    rowId: {
      type: [String, Function],
      required: false
    }
  },
  data() {
    return {};
  },
  methods: {
    selectAllEvent({checked, records}) {
      console.log(checked ? "所有勾选事件" : "所有取消事件", records);
      this.$emit("selection-change", records);
    },
    selectChangeEvent({checked, records}) {
      this.$emit("selection-change", records);
      console.log(checked ? "勾选事件" : "取消事件", records);
      this.$emit("selection-change", records);
    },
    getSelectEvent() {
      let selectRecords = this.$refs.xTable1.getCheckboxRecords();
      this.$XModal.alert(selectRecords.length);
    },
    cellClick({row, column}) {
      if (column.type === "checkbox") {
        return;
      }
      this.$emit("row-click", row);
    },
    toggleRowSelection(rows, checked) {
      if (typeof checked !== "undefined") {
        let params = [];
        if (rows instanceof Array) {
          params = rows;
        } else {
          params = [rows];
        }
        this.$refs["xTable1"].setCheckboxRow([...params], checked);
      } else {
        this.$refs["xTable1"].toggleCheckboxRow(rows);
      }

      const arr = this.$refs["xTable1"].getCheckboxRecords();
      this.$emit("selection-change", arr);
    },
    // 移除勾選狀態
    clearSelection() {
      this.$refs.xTable1.clearCheckboxRow();
      this.$refs.xTable1.clearCheckboxReserve();
    },
    setCurrentRow(row) {
      if (!row) {
        this.$refs.xTable1.clearCurrentRow();
        return;
      }
      this.$refs.xTable1.setCurrentRow(row);
    },
    getCheckboxRecords() {
    },
    cellDblclick({row}) {
      this.$emit("row-dblclick", row);
    }
  }
};
</script>

<style lang="scss">
.dsn-table {
  .vxe-body--row.row--current {
    & > td:nth-child(1) {
      background: #eee;

      &::after {
        content: "";

        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        border: 6px solid red;
        left: 6px;
        /* height: 10px; */
        background: transparent;
        border-color: transparent #409eff transparent transparent;
      }

      &::before {
        content: "";
        width: 10px;
        height: 6px;
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        left: 16px;
        background: #409eff;
      }
    }
  }
}
</style>
