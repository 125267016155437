const tagConfigEdit = {
  // 初始化状态
  state: {
    tagConfigList: {
      tagDes: "",
      //标签ID
      label: "", //标签id
      checked: true,
      useTemplateType: true,
      labelUseType: "",
      labelTemplate: "",
      labelDes: "",
      previewImage: "",
      labelCommand: "",
      labelLinkList: [],
      leftList: [],
      rightList: [],
      cloneLeftList: [], // 克隆左边的
      v1: "", //左边输入key
      v2: "", // 右边输入key
      selectDeiveName: "",
      labelConfig: "",
      s1: "",
      s2: "10",
      variablesLogic: "",
    },
    paramsData: [],
    paramsManage: {}, //变量管理当前选中行
    addVariable: {}, //添加变量
    variableSet: [], //变量属性内的值
    currentManageTab: "", //当前页签选中栏 （组件管理 变量管理 标签管理）
    allParamsNodeList: [
      {
        title: "日期",
        children: [],
        isUnFold: true,
      },
      {
        title: "公式",
        children: [],
        isUnFold: true,
      },
      {
        title: "字符串",
        children: [],
        isUnFold: true,
      },
      {
        title: "系统",
        children: [],
        isUnFold: true,
      },
      {
        title: "数组",
        children: [],
        isUnFold: true,
      },
    ], //变量管理折叠面板数据
  },
  // 编写动作
  mutations: {
    TAGCONFIGLIST(state, data) {
      state.tagConfigList = data;
    },
    PARAMSDATA(state, data) {
      state.paramsData = data;
    },
    PARAMSMANAGE(state, data) {
      state.paramsManage = data;
    },
    ADDVARIABLE(state, data) {
      state.addVariable = data;
    },
    VARIABLESET(state, data) {
      state.variableSet = data;
    },
    CURRENTMANAGETAB(state, data) {
      state.currentManageTab = data;
    },
    ALLPARAMSNODELIST(state, data) {
      state.allParamsNodeList = data;
    },
  },
  // 取值
  getters: {
    tagConfigList: function(state) {
      return state.tagConfigList;
    },
    paramsData: function(state) {
      return state.paramsData;
    },
    paramsManage: function(state) {
      return state.paramsManage;
    },
    addVariable: function(state) {
      return state.addVariable;
    },
    variableSet: function(state) {
      return state.variableSet;
    },
    currentManageTab: function(state) {
      return state.currentManageTab;
    },
    allParamsNodeList: function(state) {
      return state.allParamsNodeList;
    },
  },
  // 创建驱动，可异步
  actions: {
    change2({ commit }, data) {
      commit("TAGCONFIGLIST", data);
    },
  },
};

export default tagConfigEdit;
