//cn.js

import zhLocale from "element-ui/lib/locale/lang/zh-CN";

const cn = {
    message: {
        hello: "你好，世界",
        home: "首页",
        loginerr: '没有权限登录MES',
    },
    commbtn: {
        query: '查询',
        import: '导入',
        export: '导出',
        add: '新增',
        edit: '修改',
        start: '启动',
        stop: '停止',
        delete: '删除',
        save: '保存',
        submit: '提交',
        look: '明细',
        look1: '查看',
        detail: '详情',
        tableEmpty: '暂无数据',
        back: '返回',
        reset: '重置',
        closeLeft: '关闭左侧',
        closeRight: '关闭右边',
        closeOther: '关闭其他',
        closeAll: '关闭全部',
        confirm: '确认',
        printTag: '打印设备标签',
        startInventoryCheck: '开始盘点',
        abortInventoryCheck: '结束',
        finishInventoryCheck: '完成盘点',
        apply: '发起调拨',
        applyShift: '发起转移',
        cancel: '取消',
        exportFull: '全部导出',
        addRole: '新建角色',
        endTask: '结束',
		confirmTask:'确认',
		addgear:'新增主档',
		maingear:'设备主档',
		
    },
    commmsg: {
        tip: '提示',
        remoteApiError: '接口异常',
        notempty: '不能为空!',
        success: '成功!',
        fail: '失败!',
        yes: '是',
        no: '否',
        startdate: "开始日期",
        enddate: "结束日期",
        pleaseInput: '请输入',
        pleaseSelect: '请选择',
        cancelSave: '已取消保存',
        confirmToSave: '是否保存数据',
        addSuccess: '添加成功',
		categoryname:'设备名称',
		picerr:'格式不正确',
		upload:'上传'
    },
    home: {
        title: ['本月生产目标:', '本月计划生产天数:', '本月出货目标:', '次月出货目标:', '本月生产订单品牌占比:', '订单出货日统计']
    },
    iework: {
        tablehead: ['款号', '客户', '品牌', '成品油边', '开料', '前工序', '油边', '组合', '直接人工', '制造总工时', '图片', '导入日期', '操作'],//13
        stylehead: ['款号', '总工时(分钟)', '目标产量(100%效率)', '目标产量(92%效率)', '创建日期', '查看部件 ', '导出工艺路线图'],//7
        partshead: ['序号', '部件名称', '是否油边', '物料规格', '尺寸', '损耗', '基数', '单位', '部件类别', "取消油边", "保存油边", "筛选"],//12


        sophead: ['工站', '设备', '站位编号', '坐标', '款号', '影片', '纸格图片', '注意事项', '更新日期', '产线', '纸格图片影片', '生产顺序', '工序名称', '工位设备', '站位编号', '文件名', '上传时间', '类型', '请输入注意事项内容', '进站读卡器ID', '站位读卡器ID', '出站读卡器ID', '进站拨杆编号', 'MAC地址'],//24
        newsophead: ['生产单号', '款号', '工段产线', '路线', '生产顺序', '站位编号', '工序', '设备', '影片数量', '图片数量', '注意事项', '更新日期'],//12


        sopbtn: ['上传图片', '上传影片', '注意事项', '选择工单', '查看明细'],//5
        operabtn: ['导入模板', '导出工时工艺表', "工艺"],//3
        toptitle: ['工艺维护', '是否成品油边', '部件信息'],//3
        crafthead: ['序号', '工序描述', '机器设备', '标准工时', '工序性质', '开料', '前工序', '组合', '油边'],//9
    },
    basedata: {
        managetable: ["组织名称", 'SAP名称', '编号', '工段代码', '工厂', '下级', "名称", "站位数量", '备注', '允许查看站位', '数据'],//11
        linetable: ['生产线编号', '工段', '产线描述', '站点数', '工段描述', '状态', '更新日期'],//7
        linebtn: ['新增产线', '新增站位', '查看站位详情', '查看产线布局', '修改产线', '修改站位'],//6

    },
    prodorder: {
        ordertable: ['生产单号', '销售单', '货号', '款号', '生产数量', '客户', '品牌', '图片', '生产线', '开料', '开料开始日期', '开料结束日期', '前工序', '前工序开始日期', '前工序结束日期', '组合', '组合开始日期', '组合结束日期', '交货期', '编辑工序', '排程', '油边', '油边开始日期', '油边结束日期'],//24
        createWo: "生成排程",
        title: ['工单排程'],//1
        scheduetable: ['开料', '前工序', '组合', '产线', '工段', '产能', '排程开始日期', '排程结束日期', '产线', '产线'],//10
        linemanger: ['SAP生产线', '开料', '开料前置天数', '油边', '油边前置天数', '前工序', '前工序前置天数', '组合', '组合前置天数'],//9
        tracktable: ['款号', '生产单', '生产单数量', '车间', '生产线', '开料', '前工序', '油边', '组合'],//9
        progtable: ['生产单号', '款号', '品牌名称', '生产单数量', '订单交期', '生产线', '开料产量', '开料欠数', '油边产量', '油边欠数', '前工序产量', '前工序欠数', '组合产量', '组合欠数', '良品产量', '良品欠数', '成品油边', '车间', '配套数'],//19
        gressdetailtable: ['部件类别', '部件名称', '是否油边', '部件基数', '计划数量', '条码部件数量', '部件油边需求片数', '已打印数量', '未打印数量', '开料扫描数量', '油边接收数量', '中仓接收数量', '中仓发出数量'],//13
    },
    styleline: {
        ordertable: ['款号', '生产组', '产线', '产线类别', '创建日期', "路线", "加工顺序", "站位编号", "工序"],//9
        toptitle: ['生产组', '生产单', '选择工序'],//3
        topbtn: ['新增线路', '导出工艺路线图'],//2
        addline: ['站位编号', '工序名称', '标准工时', '合计工时', '设备'],//5
    },
    equipment: {
        menus: {
            list: '设备管理',
            add: '设备管理-添加',
            edit: '设备管理-修改',
			mainadd:'主档管理-添加',
			mainedit:'主档管理-修改',
            equipmentShift: '设备-转移',
            equipmentTransfer: '设备转移（调拨）单',
			MainGear:'主档管理'
        },
        fields: {
            category: '主分类',
            subcategory: '子分类',
            name: '设备名称',
            brand: '品牌',
            sno: '机身编码',
            sapNo: 'SAP编号',
            modelType: '型号',
            acquisitionDate: '入厂日期',
            runState: '设备运行状态',
            assetNo: '资产编号',
            sapAssetNo: 'SAP编号',
            user: '使用人',
            dept: '使用部门',
            department: '使用部门',
            director: '负责人',
            directorDept: '负责部门',
            lastPmDate: '上次维保日期',
            lastRepairedDate: '上次维修日期',
            position: '所在库位',
            condition: '设备状态',
            checkStatus: '是否已盘',
            sapCheckStatus: 'SAP验收状态',
            storage: '储位',
        }
    },
    inventorySchedule: {
        fields: {
            scheduleNo: '盘点计划单号',
            scheduleApplicant: '盘点发起人',
            category: '主分类',
            subcategory: '子分类',
            scheduleStartDate: "计划开始日期",
            scheduleEndDate: "计划结束日期",
            executor: '执行人',
            scheduleStatus: '执行状态',
            barcodeType: '条码类型',
            confirmBy: '盘点确认人',
            confirmDate: '完成时间',
        },
        constants: {
            wait: '待执行',
            checking: '正在执行',
            aborted: '已终止',
            finished: '已完成',
            unKnown: '未知'
        }
    },
    equipmentCategory: {
        fields: {
            category: '主分类',
            categoryName: '主分类名称',
            subcategoryName: '子分类',
            categoryCode: '分类编码',
            directorDept: '管理部门',
            currentCategoryName: '当前分类名称',
        }
    },
    users: {
        menus: {
            list: '用户管理',
            add: '用户管理-添加',
            edit: '用户管理-修改',
        },
        fields: {
            male: '男',
            female: '女',
            sex: '性别',
            username: '账号',
            realname: '姓名',
            pwd: '密码',
            phone: '电话',
            email: '邮箱地址',
            workNo: 'SAP账号',
            userIdentity: '',
            orgCode: '组织编码',
            department: '所在部门',
            role: '角色',
			post:'职务'
        }
    },
    errors: {
        fields: {
            serverInternalError: 'API接口异常'
        }
    },
    categories: {
        menus: {
            list: '设备分类',
            add: '设备分类-添加',
            edit: '设备分类-修改',
        },
        fields: {
            mainCategory: '大分类',
            mainCategoryCode: '大分类编码',
            middleCategoryCode: '中分类编码',
            subcategoryCode: '小分类编码',
            middleCategory: '中分类',
            category: '中分类',
            categoryName: '分类名称',
            subcategory: '小分类',
            scheduleStatus: '使用状态',
            directorDept: '管理部门',
            categoryCode: '分类编码',
        }
    },
    departments: {
        fields: {
            departmentName: '部门名称',
            departmentCode: '部门编码',
            director: '负责人',
        }
    },
    equipments: {
        fields: {
            sno: '机身编码',
            sapNo: 'SAP 编号',
            name: '设备名称',
            mainCategory: '大分类',
            middleCategory: '中分类',
            category: '主分类',
            subcategory: '小分类',
            brand: '品牌',
            modelType: '型号规格',
            assetNo: '资产编号',
            lastPMDate: '上次维护日期',
            acquisitionDate: '到厂日期',
            userDept: '使用部门',
            user: '使用人',
            director: '负责人',
            directorDept: '负责部门',
            equipmentStatus: '设备状态',
            equipmentCondition: '设备状态',
            condition: '设备状态',
            runState: '设备运行状态',
            sapCheckStatus: '验收状态',
            state: '设备状态',
            factory: '权属厂区',
            storage: '储位',
            storageKeeper: '储位负责人',
            qrCodePrintTimes: '打印次数',
            qrCode: 'QrCode',
            images: '图片',
			fromstorage:'调出储位',
			tostorage:'目标储位',
			err:'调出储位与目标储位不得相同！'
        }
    },
    tags: {
        fields: {
            count: '生成数量',
            tagDetail: '标签明细记录',
        }
    },
    storage: {
        menus: {
            list: '储位管理',
            add: '储位-添加',
            edit: '储位-编辑',
        },
        fields: {
            parentNode: '上层储位',
            storageName: '储位名称',
            costCenterNo: 'SAP成本中心',
            productGroupCode: '生产组代号',
            productionAreaFlag: '生产区域标识',
            keeper: '储位负责人',
        }
    },
    brands: {
        fields: {
            brandName: '品牌名称',
            brandFullName: '品牌全称',
            remark: '备注'
        }
    },
    transfer: {
        fields: {
            transferNo: '单据编号',
            assetNo: '资产编号',
            equipmentName: '设备名称',
            modelType: '型号规格',
            storageName: '调出储位',
            storageManager: '调出储位负责人',
            confirmResult: '调出结果',
            targetStorageName: '目标储位',
            targetConfirmResult: '调入结果',
            targetStorageManager: '目标储位负责人',
            applyUser: '申请人',
            remark: '备注',
            confirmDate: '调出确认/驳回时间',
            targetConfirmDate: '调入确认/驳回时间',
            applyTime: '申请日期',
            status: '状态',
        },
        status: {
            wait: '待确认',
            rejected: '已驳回',
            confirmed: '已确认',
        }
    },
    roles: {
        fields: {
            roleCode: '角色编号',
            roleName: '角色名称',
            remark: '备注',
            defaultRole: '默认角色'
        }
    },
    ...zhLocale
};

export default cn;
