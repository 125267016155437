import Vue from 'vue'
import XEUtils from 'xe-utils'
// import {
//     VXETable,
//     Icon,
//     Header,
//     Column,
//     Table,
//     Grid
// } from 'vxe-table'
import {Column, Edit, Grid, Header, Icon, Table, VXETable,Checkbox,Button,List} from "vxe-table";
import zhCN from 'vxe-table/lib/locale/lang/zh-CN'

// 导入默认的国际化（如果项目中使用多语言，则应该导入到 vue-i18n 中）
VXETable.setup({
    i18n: key => XEUtils.get(zhCN, key)
})

// // 按需导入依赖的模块
Vue.use(Icon)
Vue.use(Header)
Vue.use(Column)
Vue.use(Edit)
Vue.use(Checkbox)
Vue.use(Button)
Vue.use(List)
//     // 最后安装核心库
Vue.use(Table)
Vue.use(Grid)
// Vue.use(VXETable);
