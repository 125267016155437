import axios from "axios";
//import qs from "qs";
import router from "@/router/";
import {getToken} from "./action";

axios.defaults.timeout = 600000;
axios.defaults.withCredentials = false;
axios.interceptors.request.use(config => {
    // config.data = qs.stringify(config.data);
    //config.headers["Content-Type"] = "application/x-www-form-urlencoded";
    config.headers["Content-Type"] = "application/json;charset=UTF-8";
    // config.headers.Ticket = sessionStorage.getItem('Ticket');
    // config.headers["X-Auth-Token"] = getToken();
    config.headers["tpm_token"] = getToken();
    config.headers["Authorization"] = getToken();
    config.headers["tenant_id"] = '';
    // console.log(config)
    // config.headers = {
    //     "Content-Type": "application/x-www-form-urlencoded"
    // };
    // console.log(config)
    // Indicator.open({
    //     text: '加载中...',
    //     spinnerType: 'fading-circle'
    // })
    return config;
}, err => {
    return Promise.reject(err);
});

/**
 * http response 拦截器
 *
 */

axios.interceptors.response.use(response => {
    // console.log('end')
    //  Indicator.close();
    if (response.data.code == 401) {
        router.push({
            path: "/login", query: {redirect: router.currentRoute.fullPath}
        });
    } else if (response.data.errCode == 500) {
        console.log("code:500");
    }
    // console.log(response.data);
    return response;
}, 

// error => {
// 		// console.log('http error =>', error)
// 		if (error.response.status) {
// 			switch (error.response.status) {
// 				// 401: 未登录
// 				// 未登录则跳转登录页面，并携带当前页面的路径
// 				// 在登录成功后返回当前页面，这一步需要在登录页操作。
// 				case 401:
// 					Toast({
// 						message: '未登录！',
// 						duration: 1500,
// 						forbidClick: true
// 					});
// 					VueCookies.remove('userData');
// 					location.replace('/login');
// 					break;
// 					// 403 token过期
// 					// 登录过期对用户进行提示
// 					// 清除本地token和清空vuex中token对象
// 					// 跳转登录页面
// 				case 403:
// 					Toast({
// 						message: '令牌过期，请重新登录！',
// 						duration: 1500,
// 						forbidClick: true
// 					});
// 					VueCookies.remove('userData');
// 					// 清除token
// 					// 跳转登录页面，并将要浏览的页面fullPath传过去，登录成功后跳转需要访问的页面
// 					setTimeout(() => {
// 						location.replace('/login');
// 					}, 1000);
// 					break;
// 					// 404请求不存在
// 				case 404:
// 					alert('网络请求不存在！');
// 					break;
// 					// 其他错误，直接抛出错误提示
// 				default:
// 					this.$message.error(error.response.data.message);
// 			}
// 			return Promise.reject(error.response);
// 		}
// 	}
	
error => {
    if (error.response) {
        switch (error.response.status) {
            case 401:
                //500 服务器错误
                console.log("401服务器错误");
				// router.push({
				//     path: "/login", query: {redirect: router.currentRoute.fullPath}
				// });
				// this.notification().warn({
				//     body: '接口404错误，请重新登录'
				// });
				alert('账号登陆过期或被顶下线,请重新登陆');
                router.replace({
                    path: '/login',
                    query: { redirect: router.currentRoute.fullPath, title: "哎呀,服务器开小差了！", code: "404" }
                })
				
                break;
            case 404:
                //500 服务器错误
                console.log("404服务器错误");
                // router.replace({
                //     path: '/error',
                //     query: { redirect: router.currentRoute.fullPath, title: "哎呀,服务器开小差了！", code: "404" }
                // })
                // notification().warn({
                //     body: '接口404错误，请重新登录'
                // });
				
				alert('接口404错误，请刷新页面');
				// router.replace({
				//     path: '/login',
				//     query: { redirect: router.currentRoute.fullPath, title: "哎呀,服务器开小差了！", code: "404" }
				// })
                break;
            case 500:
                //500 服务器错误
                alert('接口500错误，请刷新页面');
                // router.replace({
                //     path: '/login',
                //     query: { redirect: router.currentRoute.fullPath, title: "哎呀,服务器开小差了！", code: "404" }
                // })
                break;
            default:
            alert('接口其他错误，请刷新页面');
            // router.replace({
            //     path: '/login',
            //     query: { redirect: router.currentRoute.fullPath, title: "哎呀,服务器开小差了！", code: "404" }
            // })
        }
    }
    return Promise.reject(error);
});
/**
 * 上面是axios的默认配置
 */
//Vue.prototype.$http = axios;

export default axios;
