export default {
    path: "/homeindex",
    name: "homeindex",
    component: () =>
        import ( /* webpackChunkName: "layout" */ "@/views/layout/"),
    children: [{
            path: "welcome",
            name: "welcome",
            meta: {
                title: '首页',
                requiresAuth: true,
                level: 1
            },
            component: () =>
                import (
                    /* webpackChunkName: "homeindex" */
                    "@/views/homeindex/pages/index.vue"
                )
        },


    ]
};
