window.NODE_ENV = process.env.VUE_APP_CURRENTMODE; // 环境变量
window.VUE_APP_URL = process.env.VUE_APP_URL;
window.VUE_APP_COMPANY = process.env.VUE_APP_COMPANY || "DSN"; // 环境变量
window.VUE_APP_URL = process.env.VUE_APP_URL;
window.VUE_APP_NEW_URL = process.env.VUE_APP_NEW_URL;
if (window.NODE_ENV == "development") {
    window.HOST = "ims.dissona.com";
} else {
    window.HOST = "ims.dissona.com";
}
module.exports = {
    NODE_ENV: process.env.VUE_APP_CURRENTMODE,
    VUE_APP_URL: process.env.VUE_APP_URL,
	VUE_APP_NEW_URL: process.env.VUE_APP_NEW_URL,
    VUE_APP_COMPANY: process.env.VUE_APP_COMPANY || "DSN"
};