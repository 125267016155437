export default {
    path: "/business", name: "business", meta: {
        requiresAuth: true, level: 0,
    }, component: () => import(/* webpackChunkName: "layout" */ "@/views/layout/"), children: [{
        path: "equipmentCheckSchedule", name: "equipmentCheckSchedule", meta: {
            title: "设备设施维护计划", requiresAuth: true, level: 1,
        }, component: () => import(
            /* webpackChunkName: "deviceType" */ "@/views/mes/business/equipment_check_schedule/index"
            ),
    }, {
        path: "equipmentBarcodePrint", name: "equipmentBarcodePrint", meta: {
            title: "设备设施条码打印", requiresAuth: true, level: 1,
        }, component: () => import(
            /* webpackChunkName: "barcode" */ "@/views/mes/business/equipment_tag/index"
            ),
    }, {
        path: "equipmentBarcodeAdd", name: "equipmentBarcodeAdd", meta: {
            title: "设备设施条码-新增", requiresAuth: true, level: 1,
        }, component: () => import(
            /* webpackChunkName: "barcode" */ "@/views/mes/business/equipment_tag/components/tagAdd"
            ),
    },

        {
            path: "equipmentInventorySchedule", name: "equipmentInventorySchedule", meta: {
                title: "盘点计划", requiresAuth: true, level: 1,
            }, component: () => import(
                /* webpackChunkName: "deviceType" */ "@/views/mes/business/equipment_inventory_schedule/index"
                ),
        }, {
            path: "equipmentInventoryScheduleAdd", name: "equipmentInventoryScheduleAdd", meta: {
                title: "盘点计划-添加", requiresAuth: true, level: 1,
            }, component: () => import(
                /* webpackChunkName: "inventory" */ "@/views/mes/business/equipment_inventory_schedule/components/inventorySchuduleAdd"
                ),
        }, {
            path: "equipmentInventoryScheduleEdit", name: "equipmentInventoryScheduleEdit", meta: {
                title: "盘点计划明细", requiresAuth: true, level: 1,
            }, component: () => import(
                /* webpackChunkName: "inventory" */ "@/views/mes/business/equipment_inventory_schedule/components/inventoryScheduleEdit"
                ),
        }, {
            path: "equipmentRental", name: "equipmentRental", meta: {
                title: "设备设施借调与归还", requiresAuth: true, level: 1,
            }, component: () => import(
                /* webpackChunkName: "deviceType" */ "@/views/mes/business/equipment_rental/index"
                ),
        }, ],
};
