import VueCookies from "vue-cookies";
import {getUserById} from "@/api/efms/users/users.api";

const state = {
    userinfo: {
        token: "312312312", username: 'charlie',
    }, permissionList: [], siteList: [],
};

// 取值
const getters = {
    userinfo: function (state) {
        return state.userinfo;
    }, siteList: function (state) {
        return state.siteList;
    }, permissionList: function (state) {
        return state.permissionList;
    }
};

const mutations = {
    SETUSERINFO(state, data) {
        state.userinfo = data;
    }, SETSITELIST(state, data) {
        state.siteList = data;
    }, SET_PERMISSIONLIST(state, data) {
        state.permissionList = data;
    },
};

const actions = {
    setInfo({commit}, data) {
        commit("SETUSERINFO", data);
    }, async getUserInfo({commit}, systemId) {
        let userProfile = JSON.parse(localStorage.getItem("userProfile"))
        await getUserById(userProfile.id)
            .then(result => {
                const {status, data} = result;
                if (status === 200 && data.data != null) {
                    const userProfile = data.data
                    commit("SETUSERINFO", userProfile);
                }
            })
        // commit("SETUSERINFO", {
        //     username: 'charlie', token: token
        // });
        // commit("SETSITELIST", []);
    }, Login({commit}, userInfo) {

    }, Logout({commit, state}) {

        //clear state and lc
        //redirect

    }, GetPermissionList({commit}) {
        //save uer permission in lc
        //save user btn all permission in lc
        //save user menu permission to state
        return new Promise((resolve, reject) => {
            let menuData = [{
                "path": "/welcome", "component": "welcome/index", "route": "1", "meta": {
                    "keepAlive": false,
                    "internalOrExternal": false,
                    "icon": "home",
                    "componentName": "welcome/index",
                    "title": "Home"
                }, "name": "dashboard-welcome", "id": "9502685863ab87f0ad1134142788a385"
            },]
            commit('SET_PERMISSIONLIST', menuData)
            resolve(menuData)
        })


    }, // 动态添加主界面路由，需要缓存
    // UpdateAppRouter({commit}, routes) {
    //     console.info("update router")
    //     return new Promise(resolve => {
    //         //const [ roles ] = routes.constRoutes
    //         let routelist = routes.constRoutes;
    //         commit('SET_ROUTERS', routelist)
    //         resolve()
    //     })
    // }
    // async getUserInfo({commit}, systemId) {
    //     const token = VueCookies.get("mcs.sessionId");
    //     await getUser({
    //         sessionId: token,
    //         systemId: systemId,
    //     }).then((data) => {
    //         const res = data.data;
    //         if (res.code == 200) {
    //             commit("SETUSERINFO", res.data);
    //         }
    //     });
    //     await getSiteListHttp({
    //         tenantCode: state.userinfo.tenantCode,
    //         userName: state.userinfo.name,
    //     }).then((data) => {
    //         const res = data.data;
    //         if (res.code == 200) {
    //             commit("SETSITELIST", res.data);
    //         }
    //     });
    // },

};

export default {
    state, getters, mutations, actions,
};
