<template>
  <!--  编辑 -->
  <dsn-button
    icon="el-icon-folder-add"
    @click="click"
    type="success"
    :disabled="disabled"
    :loading="loading"
    :round="round"
    :circle="circle"
    :autofocus="autofocus"
  >
    <slot></slot>
  </dsn-button>
</template>
<script>
export default {
  name: "DsnButtonEdit",
  props: {
    // 尺寸
    // size {mediun,small,mini}
    size: {
      type: String,
      default: "small"
    },
    // 类型
    // type   primary / success / warning / danger / info / text
    type: {
      type: String,
      default: "primary"
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      required: false
    },
    plain: {
      type: Boolean,
      default: false
    },
    round: {
      type: Boolean,
      default: false
    },
    circle: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },

    autofocus: {
      type: Boolean,
      default: false
    },
    nativeType: {
      type: String,
      default: "button"
    }
  },
  components: {},
  created() {},
  methods: {
    click: function() {
      this.$emit("click");
    }
  }
};
</script>
