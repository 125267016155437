<template>
	<!-- :border="border" -->
  <el-table
      v-adaptive="{bottomOffset: 70}"
      class="dsn-table"
      :height="height"
      :data="data"
      :stripe="stripe"
      :border="true"
      :emptyText="emptyText"
      :row-key="rowKey"
      :span-method="spanMethod"
      style="width:100%;font-size: 14px!important;font-family: 微软雅黑,serif"
	  :header-cell-style="{'font-size':'17px','background':'#4e81bd','font-family':'微软雅黑','font-weight':'normal','color':'#ffffff','text-align':'center'}"
	  :row-style="{background:'#f9f9f9'}"
	  :cell-style="{'font-size':'14px','font-family': '微软雅黑'}"
      @select="tableSelect"
      @row-click="tableRowClick"
      @row-dblclick="tableRowDblclick"
      @cell-click="tableCellClick"
      @select-all="tableSelectAll"
      @selection-change="tableSelectionChange"
      @current-change="currentChange"
      :highlight-current-row="highlightCurrentRow"
      :row-class-name="rowClassName"
      default-expand-all
      :indent="35"
      :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
      ref="table"
  >
    <!-- <el-table-column v-if="highlightCurrentRow&&showArrow" label width="40">
      <template>
        <span class="arrow">
          <i class="el-icon-right" v-if="highlightCurrentRow&&showArrow"></i>
        </span>
      </template>
    </el-table-column> -->
    <slot></slot>
  </el-table>
</template>

<script>
import tableMixin from "./table.mixin";

export default {
  name: "dsnTable",
  mixins: [tableMixin],
  props: {
    // 数据源
    data: {
      type: Array,
      default: () => {
        return [];
      }
    },
    "highlight-current-row": {
      type: Boolean,
      default: false
    },
    //  key
    rowKey: {
      type: String,
      required: false
    },
    //高度
    height: {
      type: [Number, String],
      default: "490px"
    },
    select: {
      type: Function,
      required: false
    },
    "row-click": {
      type: Function,
      required: false
    },
    //最大的高度
    "max-height": {
      type: [Number, String],
      required: false
    },
    showArrow: {
      type: Boolean,
      default: true
    },
    //是否是斑马纹
    stripe: {
      type: Boolean,
      default: true
    },
    //medium  small mini
    size: {
      type: String,
      default: "medium"
    },
    border: {
      type: Boolean,
      default: false
    },
    "row-class-name": {
      type: Function,
      required: false
    },
    emptyText: {
      type: String,
      default: "没有数据啊"
    },
    rowClassName: {
      type: Function,
      required: false
    },
    highlightCurrentRow: {
      type: Boolean,
      default: false
    },
    showCurrent: {
      type: Boolean,
      default: true
    },
    spanMethod: {
      type: Function,
      required: false
    }
  },
  data() {
    return {
      currentRow: {}
    };
  },
  methods: {
    // 用于多选表格，清空用户的选择
    // currentChange(val) {
    //   this.currentRow = val;
    // }
  }
};
</script>

<style lang="scss">
.dsn-table {
  .arrow {
    display: none;
  }

  .el-table__row.current-row {
    .arrow {
      display: block;
	 

      .el-icon-right {
        color: #409eff;
        font-size: 14px;
      }

      // background: #eee;
      // &::after {
      //   content: "";

      //   top: 50%;
      //   transform: translateY(-50%);
      //   position: absolute;
      //   border: 6px solid red;
      //   left: 6px;
      //   /* height: 10px; */
      //   background: transparent;
      //   border-color: transparent #409eff transparent transparent;
      // }
      //   &::before {
      //     content: "";
      //     width: 10px;
      //     height: 6px;
      //     top: 50%;
      //     transform: translateY(-50%);
      //     position: absolute;
      //     left: 16px;
      //     background: #409eff;
      //   }
    }
  }
}
</style>
