<template>
  <el-tag class="help-tag" size="small" disable-transitions closable @close="close">
    <slot></slot>
  </el-tag>
  <!-- <div class="help-item">
    <div class="dsn-router-history-box">
      <span class="left_com" >
          <slot></slot>
      </span>
      <span class="right_com"
        ><i class="el-icon-close" @click.self="close"></i
      ></span>
    </div>
  </div>-->
</template>

<script>
export default {
  name: "helpItem",
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {};
  },
  methods: {
    close() {
      console.log("close");
      this.$emit("close", this.item);
    },
    toPage(item) {
      if (this.$route.name === item.name) {
        return;
      }

      this.$emit("toPage", item);
    }
  }
};
</script>
<style lang="scss">
.help-tag {
  margin-right: 8px;
  margin-bottom: 8px;
  background-color: #ecf5ff;
  border-color: #b3d8ff;
  color: #0486fe;
}
</style>
