const departmentListEdit = {
    // 初始化状态
    state: {
        departmentList: []
    }, // 编写动作
    mutations: {
        DEPARTMENTLIST(state, data) {
            state.departmentList = data;
        }
    }, // 取值
    getters: {
        departmentList: function (state) {
            return state.departmentList;
        }
    }, // 创建驱动，可异步
    actions: {
        change2({commit}, data) {
            commit("DEPARTMENTLIST", data);
        }
    }
};

export default departmentListEdit;
