const inventoryScheduleListEdit = {
    // 初始化状态
    state: {
        inventoryScheduleList: []
    }, // 编写动作
    mutations: {
        INVENTORYSCHEDULELIST(state, data) {
            state.inventoryScheduleList = data;
        }
    }, // 取值
    getters: {
        inventoryScheduleList: function (state) {
            return state.inventoryScheduleList;
        }
    }, // 创建驱动，可异步
    actions: {
        change2({commit}, data) {
            commit("INVENTORYSCHEDULELIST", data);
        }
    }
};

export default inventoryScheduleListEdit;
