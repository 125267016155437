var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-table-column',{attrs:{"type":_vm.type,"label":_vm.label,"prop":_vm.prop,"index":_vm.index,"column-key":_vm.columnKey,"width":_vm.width,"min-width":_vm.minWidth,"fixed":_vm.fixed,"render-header":_vm.renderHeader,"sortable":_vm.sortable,"sort-method":_vm.sortMethod,"sort-by":_vm.sortBy,"sort-orders":_vm.sortOrders,"formatter":_vm.formatter,"show-overflow-tooltip":_vm.showOverflowTooltip,"align":_vm.align,"header-align":_vm.headerAlign,"class-name":_vm.className,"label-class-name":_vm.labelClassName,"selectable":_vm.selectable,"reserve-selection":_vm.reserveSelection,"filters":_vm.filters,"filter-placement":_vm.filterPlacement,"filter-multiple":_vm.filterMultiple,"filter-method":_vm.filterMethod,"filtered-value":_vm.filteredValue},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var column = ref.column;
var $index = ref.$index;
return [_vm._t("header",null,{"column":column,"$index":$index})]}},{key:"default",fn:function(ref){
var row = ref.row;
var column = ref.column;
var $index = ref.$index;
return [_vm._t("default",null,{"row":row,"column":column,"$index":$index})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }