const materialGroupEdit = {
    // 初始化状态
    state: {
        firstChoice: '', //首次选择要编辑或添加行
        messageList: [], // 编辑页面的数据
        choiceNum: "", //编辑页面的序号
        materialGroupList: [],
        changeMessage: [],
        choiceRow: "", //按钮组选中按钮是第几行的
        allMessage: {
            canChangeOperation: "",
            canChangeQty: "",
            canChangeResource: "",
            createTime: "",
            createUserId: "",
            createUserName: "",
            defaultOperationRef: "",
            defaultResourceRef: "",
            deleteFlag: "",
            lotInWorkButtonId: "",
            lotQueueButtonId: "",
            modifyTime: "",
            modifyUserId: "",
            modifyUserName: "",
            podButtons: [
                // {
                //   buttionDesc: "",
                //   buttonId: "",
                //   groupFlag: true,
                //   imageIcon: "",
                //   location: "",
                //   podActivities: [
                //     {
                //       activity: "",
                //       activityDes: "",
                //       activityType: "",
                //       clearsLot: true,
                //       podButtonRef: "",
                //       reference: "",
                //       sequence: "",
                //       tenantSiteCode: ""
                //     }
                //   ],
                //   podButtonRef: "",
                //   podButtons: [null],
                //   podConfigRef: "",
                //   reference: "",
                //   sequence: "",
                //   tenantSiteCode: ""
                // }
            ],
            podConfigDesc: "",
            podConfigName: "",
            reference: "",
            showQty: "",
            status: true,

        },
        proRow: [],
    },
    // 编写动作
    mutations: {
        MATERIALGROUPLIST(state, data) {
            state.materialGroupList = data;
        },
        PROROW(state, data) {
            state.proRow = data;
        },
        ALLMESSAGE(state, data) {
            state.allMessage = data;
        },
        CHOICEROW(state, data) {
            state.choiceRow = data;
        },
        MESSAGELIST(state, data) {
            state.messageList = data;
        },
        CHOICENUM(state, data) {
            state.choiceNum = data;
        },
        CHANGEMESSAGE(state, data) {
            state.changeMessage = data;
        },
        FIRSTCHOICE(state, data) {
            state.firstChoice = data;
        },
    },
    // 取值
    getters: {
        materialGroupList: function(state) {
            return state.materialGroupList;
        },
        proRow: function(state) {
            return state.proRow;
        },
        allMessage: function(state) {
            return state.allMessage;
        },
        choiceRow: function(state) {
            return state.choiceRow;
        },
        messageList: function(state) {
            return state.messageList;
        },
        choiceNum: function(state) {
            return state.choiceNum;
        },
        changeMessage: function(state) {
            return state.changeMessage;
        },
        firstChoice: function(state) {
            return state.firstChoice;
        },
    },
    // 创建驱动，可异步
    actions: {
        change2({ commit }, data) {
            commit("MATERIALGROUPLIST", data);
        }
    }
};

export default materialGroupEdit;