export default {
	path: "/equipments",
	name: "equipments",
	// meta: {
	// 	requiresAuth: true,
	// 	level: 0,
	// },
	component: () => import( /* webpackChunkName: "layout" */ "@/views/layout/"),
	children: [{
		path: "equipmentList",
		name: "equipmentList",
		meta: {
			title: "设备管理",
			titleI18n: "equipment.menus.list",
			requiresAuth: true,
			level: 1,
		},
		component: () => import(
			/* webpackChunkName: "deviceType" */
			"@/views/mes/equipment/index.vue"
		),
	}, {
		path: "equipmentEdit",
		name: "equipmentEdit",
		meta: {
			title: "设备编辑",
			titleI18n: "equipment.menus.edit",
			requiresAuth: true,
			level: 1,
		},
		component: () => import(
			/* webpackChunkName: "standingMaintenanceEdit" */
			"@/views/mes/equipment/components/equipmentEdit.vue"
		)
	}, {
		path: "equipmentAdd",
		name: "equipmentAdd",
		meta: {
			title: "设备添加",
			titleI18n: "equipment.menus.add",
			requiresAuth: true,
			level: 1,
		},
		component: () => import(
			/* webpackChunkName: "standingMaintenanceEdit" */
			"@/views/mes/equipment/components/equipmentAdd.vue"
		)
	}, {
		path: "equipmentTransfer",
		name: "equipmentTransfer",
		meta: {
			titleI18n: "equipment.menus.equipmentTransfer",
			title: "设备转移（调拨）单",
			requiresAuth: true,
			level: 1,
		},
		component: () => import(
			/* webpackChunkName: "deviceType" */
			"@/views/mes/equipment_transfer/index"
		),
	}, {
		path: "equipmentShift",
		name: "equipmentShift",
		meta: {
			titleI18n: "equipment.menus.equipmentShift",
			title: "设备转移",
			requiresAuth: true,
			level: 1,
		},
		component: () => import(
			/* webpackChunkName: "deviceType" */
			"@/views/mes/equipment_shift/index"
		),
	},{
		path: "mainGear",
		name: "mainGear",
		meta: {
			title: "主档管理",
			titleI18n: "equipment.menus.MainGear",
			requiresAuth: true,
			level: 1,
		},
		component: () => import(
			/* webpackChunkName: "deviceType" */
			"@/views/mes/equipment/mainGear.vue"
		),
	}, {
		path: "mainEquipmentAdd",
		name: "mainEquipmentAdd",
		meta: {
			title: "主档管理-添加",
			titleI18n: "equipment.menus.mainadd",
			requiresAuth: true,
			level: 1,
		},
		component: () => import(
			/* webpackChunkName: "standingMaintenanceEdit" */
			"@/views/mes/equipment/components/mainEquipmentAdd.vue"
		)
	}, {
		path: "mainEquipmentEdit",
		name: "mainEquipmentEdit",
		meta: {
			title: "主档管理-编辑",
			titleI18n: "equipment.menus.mainedit",
			requiresAuth: true,
			level: 1,
		},
		component: () => import(
			/* webpackChunkName: "standingMaintenanceEdit" */
			"@/views/mes/equipment/components/mainEquipmentEdit.vue"
		)
	}],
};
