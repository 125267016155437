//cn.js

import zhLocale from "element-ui/lib/locale/lang/zh-CN";

const cn = {
    message: {
        hello: "你好，世界"
    },
    commbtn: {
        query: 'Search',
        import: 'Import',
        export: 'Export',
        add: 'Add',
        edit: 'Edit',
        start: 'Start',
        stop: 'Stop',
        delete: 'Delete',
        save: 'Save',
        back: 'Back',
        reset: 'Reset',
        closeLeft: 'Close Left',
        closeRight: 'CLose Right',
        closeOther: 'Close Other',
        closeAll: 'Close All',
        confirm: 'Confirm',
        printTag: 'Print Tag',
        apply: 'Apply',
        applyShift: '发起转移',
        submit: 'Submit',
        detail: 'Detail',
        cancel: 'Close',
        exportFull: 'Export All',
        addRole: 'Add Role',
        endTask: 'End',
		confirmTask:'confirm',
		addgear:'addgear',
		maingear:'maingear',
		upload:'upload'
    },
    commmsg: {
        tip: '提示',
        notempty: '不能为空!',
        remoteApiError: '接口异常',
        success: '成功!',
        fail: '失败!',
        yes: '是',
        no: '否',
        startdate: "开始日期",
        enddate: "结束日期",
        pleaseInput: 'Please Input',
        pleaseSelect: 'Please Select',
        cancelSave: 'cancelled to save',
        confirmToSave: 'confirm to save',
        addSuccess: 'Ok',
		categoryname:'categoryname',
		picerr:'格式不正确'
    },
    iework: {
        tablehead: ['款号', '客户', '品牌', '成品油边', '开料', '前工序', '油边', '组装', '直接人工', '制造总工时', '图片', '导入日期', '操作', '款号:'],
        operabtn: ['导入模板', '导出工时工艺表'],
        toptitle: ['工艺维护', '是否成品油边'],
        crafthead: ['序号', '工序描述', '机器设备', '标准工时', '工序性质', '开料', '前工序', '组合', '油边'],
    },
    equipment: {
        menus: {
            list: 'Equipment List',
            add: 'Equipment-Add',
            edit: 'Equipment-Edit',
			mainadd:'MainEquipment-Add',
			mainedit:'MainEquipment-Edit',
            equipmentShift: 'Equipment Shift',
            equipmentTransfer: 'Equipment Shifts(Transfer)',
			MainGear:'Master File Management'
        },
        btn: {},
        fields: {
            category: '主分类',
            subcategory: '子分类',
            name: 'Equipment Name',
            brand: 'Brand',
            sno: 'Sno',
            modelType: 'Model',
            acquisitionDate: 'Acquisition Date',
            runState: 'Running Status',
            assetNo: 'Asset No',
            sapAssetNo: 'SAP NO.',
            userId: 'User',
            deptId: 'User Department',
            department: 'User Department',
            director: 'Director',
            directorDept: 'Director Department',
            lastPmDate: 'Last Pm Date',
            position: 'Position',
            condition: 'Condition',
            checkStatus: 'Check Status'

        }
    },
    inventorySchedule: {
        fields: {
            scheduleNo: 'Schedule No',
            scheduleApplicant: 'Applicant',
            category: 'Category',
            subcategory: 'Subcategory',
            scheduleStartDate: "Start Date",
            scheduleEndDate: "End Date",
            executor: 'Executor',
            scheduleStatus: 'Execution Status',
            confirmBy: 'Confirmed By',
            confirmDate: 'Confirmed Date',
        },
        constants: {
            wait: 'Waiting',
            checking: 'Checking',
            aborted: 'Aborted',
            finished: 'Finished'
        }
    },
    equipmentTag: {
        menus: {
            list: '设备设施标签库',
            add: '设备设施标签-添加',
            edit: '设备设施标签-修改',
        },
        btn: {
            printTag: '打印标签'
        },
        fields: {}
    },

    ticket: {
        menus: {},
        btn: {},
        fields: {}
    },
    users: {
        menus: {
            list: 'User Management',
            add: 'Use-Add',
            edit: 'Use-Edit',
        },
        fields: {
            male: 'Male',
            female: 'Female',
            username: 'Account',
            sex: 'Gender',
            realname: 'Real Name',
            phone: 'Phone',
            email: 'Email',
            workNo: 'SAP Account',
            userIdentity: '',
            orgCode: 'Org Code',
            pwd: 'Password',
            department: 'Department',
            role: 'Role',
			post:'worker'
        }
    },
    categories: {
        menus: {
            list: 'Equipment Category List',
            add: 'Equipment Category-Add',
            edit: 'Equipment Category-Edit',
        },
        fields: {
            mainCategory: 'Main Category',
            middleCategory: 'Middle Category',
            mainCategoryCode: 'Main Code',
            middleCategoryCode: 'Middle Code',
            subcategoryCode: 'Sub Code',
            subcategory: 'Subcategory',
            category: 'Category',
            categoryName: 'Category Name',
            scheduleStatus: 'Status',
            categoryCode: 'Category Code',
            directorDept: 'Management Department',
        }
    },
    departments: {
        fields: {
            departmentName: 'Department Name',
            departmentCode: 'Department Code',
            director: 'Director',
        }
    },
    equipments: {
        fields: {
            sno: 'Sno',
            sapNo: 'SAP No.',
            name: 'Equipment Name',
            mainCategory: 'Main Category',
            middleCategory: 'Middle Category',
            category: 'Category',
            subcategory: 'Subcategory',
            brand: 'Brand',
            modelType: 'Model Type',
            assetNo: 'Asset No',
            lastPMDate: 'Last Pm Date',
            acquisitionDate: 'Acquisition Date',
            userDept: 'User Dept',
            user: 'User',
            director: 'Director',
            directorDept: 'Director Dept',
            equipmentStatus: 'Status',
            equipmentCondition: 'Condition',
            runState: 'Run State',
            sapCheckStatus: 'SAP Check status',
            checkStatus: 'Check Status',
            factory: 'Factory',
            storage: 'Storage',
            storageKeeper: 'Storage Keeper',
            qrCodePrintTimes: 'Print Times',
            qrCode: 'QrCode',
            images: 'Images',
			fromstorage:'fromstorage',
			tostorage:'tostorage',
			err:'调出储位与目标储位不得相同！'
        }
    },
    tags: {
        fields: {
            count: 'Tag Qty',
            tagDetail: 'Tag Detail Records',
        }
    },
    storage: {
        menus: {
            list: 'Storage Management',
            add: 'Storage-Add',
            edit: 'Storage-Edit',
        },
        fields: {
            parentNode: 'Parent Storage',
            storageName: 'Storage Name',
            costCenterNo: 'SAP Cost Center No.',
            productGroupCode: 'Production Group Code',
            productionAreaFlag: 'Production Area Flag',
            keeper: 'Storage Keeper',
        }
    },
    brands: {
        fields: {
            brandName: 'Brand Name',
            brandFullName: 'Brand FullName',
            remark: 'Remark'
        }
    },
    transfer: {
        fields: {
            transferNo: 'No.',
            storageManager: 'Storage Manager',
            targetConfirmResult: 'Target Confirm/Reject',
            targetStorageManager: 'Target Storage Manager',
            storageName: 'Storage',
            targetStorageName: 'Target Storage',
            remark: 'Remark',
            confirmDate: 'Confirm Date',
            applyTime: 'Apply Date',
            confirmResult: 'Confirm Result',
        },
        status: {
            wait: 'Wait',
            rejected: 'Rejected',
            confirmed: 'Confirmed',
        }
    },
    roles: {
        fields: {
            roleName: 'Role Name',
            roleCode: 'Role Code',
            remark: 'Remark',
            defaultRole: 'Default Role'
        }
    },
    ...zhLocale
};

export default cn;
