<template>
  <!-- 穿梭框 -->
  <div class="shuttle-table">
    <div class="left table">
      <slot name="left-header">
        <div class="header">
          <div class="left">
            <slot name="left-title">left</slot>
          </div>
          <div class="right">
            <dsn-input v-model="leftKey"></dsn-input>
          </div>
        </div>
      </slot>
      <dsn-table
        border
        ref="leftTable"
        @selection-change="handleLeftSelectionChange"
        class="left"
        :data="computedLeftData"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <slot name="table"></slot>
      </dsn-table>
    </div>
    <div class="center">
      <dsn-button
        type="primary"
        @click="toLeft"
        :disabled="rightSelect.length <= 0"
        icon="el-icon-caret-left"
      ></dsn-button>
      <dsn-button
        type="primary"
        @click="toRight"
        :disabled="leftSelect.length <= 0"
        icon="el-icon-caret-right"
      ></dsn-button>
    </div>
    <div class="right table">
      <slot name="right-header">
        <div class="header">
          <div class="left">
            <slot name="right-title">left</slot>
          </div>
          <div class="right">
            <dsn-input v-model="rightKey"></dsn-input>
          </div>
        </div>
      </slot>
      <dsn-table
        ref="rightTable"
        border
        :data="computedRightData"
        @selection-change="handleRightSelectionChange"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <slot name="table"></slot>
      </dsn-table>
    </div>
  </div>
</template>
<script>
export default {
  name: "DsnShuttleTable",
  model: {
    prop: "value",
    event: "change"
  },
  computed: {
    computedLeftData() {
      if (this.search) {
        return this.value.filter(item => this.search(item, this.leftKey));
      }

      return this.value;
    },
    computedRightData() {
      if (this.search) {
        return this.rightData.filter(item => this.search(item, this.rightKey));
      }

      return this.rightData;
    },
    leftKeyArr() {
      return this.value.map(item => {
        return this.getKey(item);
      });
    },
    rightKeyArr() {
      return this.rightData.map(item => {
        return this.getKey(item);
      });
    }
  },
  watch: {
    rightDefaultChecked(newV) {
      this.rightData = [...newV];
    }
  },
  props: {
    data: {
      type: Array,
      default: () => {}
    },
    value: {
      type: Array,
      required: true
    },
    getKey: {
      type: Function,
      required: false
    },
    search: {
      type: Function,
      required: false
    },
    rightDefaultChecked: {
      type: Array,
      required: false
    }
  },
  data() {
    return {
      leftKey: "",
      rightKey: "",
      rightData: [],
      leftSelect: [],
      rightSelect: []
    };
  },
  methods: {
    handleLeftSelectionChange(arr) {
      this.leftSelect = arr;
    },
    handleRightSelectionChange(arr) {
      this.rightSelect = arr;
    },
    toLeft() {
      // 左边的减少
      // 右边的增加
      //1 去掉重复的選中
      const selectArr = this.rightSelect.filter(item => {
        return !this.leftKeyArr.includes(this.getKey(item));
      });
      const selectKeyArr = selectArr.map(item => {
        return this.getKey(item);
      });
      this.rightData = this.rightData.filter(item => {
        return !selectKeyArr.includes(this.getKey(item));
      });

      const leftData = [...this.value, ...selectArr];
      this.$refs.rightTable.clearSelection();
      this.$refs.leftTable.clearSelection();
      this.$emit("change", leftData);

      this.$emit("select-change", this.rightData);
    },
    toRight() {
      // 左边的减少
      // 右边的增加
      //1 去掉重复的選中
      const selectArr = this.leftSelect.filter(item => {
        return !this.rightKeyArr.includes(this.getKey(item));
      });
      const selectKeyArr = selectArr.map(item => {
        return this.getKey(item);
      });

      const leftData = this.value.filter(item => {
        console.log(selectKeyArr, this.getKey(item));
        console.log(selectKeyArr.includes(this.getKey(item)));
        return !selectKeyArr.includes(this.getKey(item));
      });
      this.rightData = [...this.rightData, ...selectArr];
      this.$refs.rightTable.clearSelection();
      this.$refs.leftTable.clearSelection();
      this.$emit("change", leftData);
      this.$emit("select-change", this.rightData);
    }
  }
};
</script>

<style lang="scss">
.shuttle-table {
  border: 1px solid #eee;
  display: flex;
  box-sizing: border-box;
  .center {
    padding: 16px;
    display: flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #fff;
    .dsn-button {
      margin: 0 0 8px 0;
    }
  }
  .table {
    flex: 1;
    flex-wrap: nowrap;
  }
  .header {
    background: #eee;
    padding: 8px;
    display: flex;
    flex-wrap: nowrap;
    & > .left {
      width: 300px;
    }
    & > .right {
      flex: 1;
      .dsn-input {
        width: 100%;
      }
    }
  }
}
</style>
