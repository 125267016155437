const userListEdit = {
    // 初始化状态
    state: {
        userList: []
    },
    // 编写动作
    mutations: {
        USERSLIST(state, data) {
            state.userList = data;
        }
    },
    // 取值
    getters: {
        userList: function(state) {
            return state.userList;
        }
    },
    // 创建驱动，可异步
    actions: {
        change2({ commit }, data) {
            commit("USERSLIST", data);
        }
    }
};

export default userListEdit;
