<template>
  <div class="DsnPanel">
    <el-card class="box-card" shadow="never">
      <template slot="header">
        <div class="header">
          <slot name="header"></slot>
        </div>
      </template>
      <slot></slot>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "DsnPanel",
  data() {
    return {};
  }
};
</script>

<style lang="scss">
.DsnPanel {
  .el-card {
    margin-bottom: $sectionPaddingBottom;
    .el-card__header {
      padding: 16px 16px 0 16px;
    }
    .el-card__body {
      padding: 16px;
      // min-height: 1000px;
    }

    // .title {
    //   display: inline-block;
    //   width: 100%;
    //   height: 20px;
    //   line-height: 20px;
    //   font-size: 15px;
    //   color: #17233d;
    //   font-weight: 500;
    //   overflow: hidden;
    //   text-overflow: ellipsis;
    //   white-space: nowrap;
    // }
    .pagination {
      margin-top: 15px;
    }
    .header {
      .el-form {
        .el-form-item {
          margin-bottom: 16px;
          margin-right: 24px;
        }
        .dsn-button {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
