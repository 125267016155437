<template>
  <el-dialog
      :width="width"
      class="help-dialog"
      :title="title"
      :visible.sync="visible"
      :before-close="handleCancle"
      v-el-drag-dialog
  >
    <slot name="header"></slot>
    <div class="body">
      <!-- 这里插入的是table -->
      <dsn-vue-table
          ref="table"
          :reserve-selection="true"
          v-loading="isLoading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          :highlight-current-row="false"
          :data="tableData"
          @row-click="RowClick"
          @selection-change="handleSelectionChange"
          :row-key="rowKey"
          :row-id="rowKey"
          @row-dblclick="RowDoubleClick"
          height="400px"
      >
        <vxe-table-column
            type="checkbox"
            :reserve-selection="true"
            v-if="!isSingle"
            width="55px"
        ></vxe-table-column>
        <!-- 这里插入的是table 的行 -->
        <slot name="body"></slot>
      </dsn-vue-table>
      <div class="warningBox">
        <p class="warning">
          <span>数据条数：{{ tableData.length }}</span>
          &nbsp;
          <span v-show="tableData.length > maxShowLimit"
          >已达最大显示行数：{{ maxShowLimit }}</span
          >
        </p>
      </div>
      <DsnHelpFooter
          :selectLimit="selectLimit"
          v-if="!isSingle"
          :len="totalSelectArr.length"
          @clear="handleClear"
      >
        <DsnHelpItem
            :key="index"
            :item="item"
            v-for="(item, index) in totalSelectArr"
            @close="close"
        >{{ helpText(item) }}
        </DsnHelpItem
        >
      </DsnHelpFooter>
    </div>
    <p class="maxSelectWarning" v-if="totalSelectArr.length > selectLimit">
      最大多选行数为：{{ selectLimit }} 行, 已选 {{ totalSelectArr.length }} 行
    </p>
    <span slot="footer" class="dialog-footer" v-if="showFooter">
      <dsn-button
          size="medium"
          type
          plain
          class="reset-button"
          @click="handleCancle"
      >取消</dsn-button
      >
      <dsn-button type="primary" @click="confirm" size="medium">确定</dsn-button>
    </span>
  </el-dialog>
</template>

<script>
import _ from "lodash";

export default {
  name: "DsnSelectDialog",
  model: {
    prop: "data",
    event: "change",
  },
  watch: {
    // 监听表格的数据变化
    tableData: {
      handler: function (newArr) {
        this.$nextTick(() => {
          this.tableData = newArr;

          this.totalSelectArr = _.cloneDeep(this.data);
          this.setSelected();
        });
      },
      deep: true,
    },
    visible: {
      handler: function (newv) {
        this.totalSelectArr = _.cloneDeep(this.data);
        if (newv) {
          this.$nextTick(() => {
            this.clearSelect();
            this.setSelected();
          });
        }
      },
      deep: true,
    },
  },
  computed: {
    selectedKeyArr() {
      return this.totalSelectArr.map((item) => {
        return (this.isEqualFn && this.isEqualFn(item)) || item[this.keyValue];
      });
    },
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    // 最大选中的限制
    selectLimit: {
      type: Number,
      default: 10,
    },
    // 最大显示的限制
    maxShowLimit: {
      type: Number,
      default: 1000,
    },
    keyValue: {
      type: String,
      required: true,
    },
    title: {
      type: [String],
      default: "筛选",
    },
    width: {
      type: [String, Number],
      default: "500px",
    },
    rowKey: {
      type: [Function, String],
      required: true,
    },
    data: {
      type: Array,
      default: () => {
        return {};
      },
    },
    visible: {
      type: Boolean,
      required: true,
    },
    isSingle: {
      type: Boolean,
      default: true,
    },
    tableData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    helpText: {
      type: [Function],
      required: true,
    },
    isEqualFn: {
      type: Function,
      required: false,
    },
    showFooter: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      // 被选中的值
      selectArr: [],
      totalSelectArr: [],
    };
  },
  created() {
  },
  methods: {
    getRowKey(row) {
      return row.lot;
    },
    handleSelectionChange(arr) {
      this.totalSelectArr = arr;
      console.info(this.totalSelectArr)
    },

    handleSelect(_, row) {
      // this.$refs["table"].toggleRowSelection(row);
      if (this.isSingle) {
        this.totalSelectArr = [row];
        return;
      }
      const len = this.totalSelectArr.length;
      const isContain = this.selectedKeyArr.includes(
          (this.isEqualFn && this.isEqualFn(row)) || row[this.keyValue]
      );
      if (isContain) {
        this.$refs["table"].toggleRowSelection(row);
      } else {
        if (len >= 10) {
          this.$refs["table"].toggleRowSelection(row);
        } else {
          this.$refs["table"].toggleRowSelection(row);
        }
      }
    },
    // 清空选中状态的方法
    clearSelect() {
      this.$refs["table"] && this.$refs["table"].clearSelection();
      this.$refs["table"] && this.$refs["table"].setCurrentRow();

      //this.totalSelectArr = [];
    },
    handleCancle() {
      this.totalSelectArr = _.cloneDeep(this.data);
      this.clearSelect();
      this.$emit("cancle");
      // this.$emit("update:visible", false);
    },
    confirm() {
      //  this.$emit("change", this.totalSelectArr);
      this.$emit("confirm", this.totalSelectArr);

      //this.$emit("update:visible", false);
    },
    close(deleteItem) {
      // this.clearSelect();
      //  从选中的里面删除数据
      this.totalSelectArr = this.totalSelectArr.filter((item) => {
        if (this.isEqualFn) {
          return this.isEqualFn(item) != this.isEqualFn(deleteItem);
        }
        return item[this.keyValue] != deleteItem[this.keyValue];
      });

      this.$refs["table"].toggleRowSelection(deleteItem);
      // this.$emit("change", this.totalSelectArr);
    },
    // 设置选中状态
    setSelected() {
      this.$refs["table"] && this.$refs["table"].clearSelection();
      const keyArr = this.totalSelectArr.map((item) => {
        return (this.isEqualFn && this.isEqualFn(item)) || item[this.keyValue];
      });
      this.tableData.forEach((item) => {
        const isContain = keyArr.includes(
            (this.isEqualFn && this.isEqualFn(item)) || item[this.keyValue]
        );
        if (isContain) {
          // 存在就设置选中

          this.$refs["table"].setCurrentRow(item);
          this.$refs["table"].toggleRowSelection(item, true);
        }
      });
    },
    RowClick(row) {
      // 设置选中的状态
      console.log("click");
      if (this.isSingle) {
        this.totalSelectArr = [row];
        this.$refs["table"] && this.$refs["table"].clearSelection();
        this.$refs["table"].setCurrentRow(row);
        this.$refs["table"].toggleRowSelection(row);
        return;
      }
      this.$refs["table"].toggleRowSelection(row);
    },
    // 双击选中
    RowDoubleClick(row) {
      if (this.isSingle) {
        this.totalSelectArr = [row];
        this.$emit("confirm", [row]);
        return;
      }
      this.RowClick(row);
      this.$emit("confirm", this.totalSelectArr);
    },
    handleClear() {
      this.totalSelectArr = [];
      this.clearSelect(); //
      // this.$emit("change", this.totalSelectArr);
      this.$emit("clearAll");
    },
    resert() {
      this.totalSelectArr = [];
      this.clearSelect(); //
    },
  },
};
</script>

<style lang="scss">
.help-dialog {
  .vxe-table .vxe-body--column.col--ellipsis,
  .vxe-table.vxe-editable .vxe-body--column,
  .vxe-table .vxe-footer--column.col--ellipsis,
  .vxe-table .vxe-header--column.col--ellipsis {
    height: 40px;
  }

  .el-dialog__body {
    padding: 5px 24px;

    .el-form {
      .el-form-item {
        margin-bottom: 8px;

        .dsn-button {
          margin-bottom: 0;
        }
      }
    }
  }

  .warningBox {
    height: 24px;

    .warning {
      font-size: 14px;
      color: #e6a23c;
      line-height: 24px;
    }
  }

  .maxSelectWarning {
    font-size: 12px;
    color: #e6a23c;
  }

  .el-dialog__header {
    background: #f5f7fa;
  }

  .el-dialog__footer {
    // padding-top: 0px;
    // padding-bottom: 24px;
    .dsn-button {
      margin-bottom: 0;
    }
  }
}
</style>
