/**
 *  跳转到登录页面
 * to 要跳转的路径  如 /home
 * next  责任链模式
 */
import { TOKEN, DEVELOPMENT } from "@/constant";
import VueCookies from "vue-cookies";

export const toLogin = (system, from) => {
  from = from || "";
  if (window.NODE_ENV === DEVELOPMENT) {
    window.location.href = `http://${window.location.host}/login?redirect=${from}`;
	// window.location.href = `http://${window.location.host}/login`;
  } else {
    window.location.href = `http://${window.location.host}/login`; // 首页就是登录页面
  }
};

export const toLogin2 = (system, from) => {
  from = from || "";
  if (window.NODE_ENV === DEVELOPMENT) {
    window.location.href = `http://${window.location.host}/user/login?redirect=${from}`;
    // window.location.href = `http://${window.location.host}/login`;
  } else {
    window.location.href = `http://${window.location.host}/user/login`; // 首页就是登录页面
  }
};

export const toHome = system => {
  console.log(DEVELOPMENT);

  if (window.NODE_ENV === DEVELOPMENT) {
    window.location.href = `http://${window.location.host}/`;
  } else {
    // window.location.href = `http://${window.location.host}/${system.toLowerCase()}/`;
	window.location.href = `http://${window.location.host}/`;
  }
};

/**
 *  删除掉等级的cookie
 */
export const clearToken = () => {
  //开发环境删除的是本地的cookie
  if (Window.NODE_ENV === DEVELOPMENT) {
    VueCookies.remove(TOKEN);
  } else {
    // 先上环境删除的是顶级的cookie
    VueCookies.remove(TOKEN);
  }
};
/**
 *   设置顶级的cookie
 */
export const setToken = token => {
  //开发环境删除的是本地的cookie
  if (window.NODE_ENV == "development") {
    // 当前设置
    VueCookies.set(TOKEN, token, { expires: "8h" });
  } else {
    // 先上环境删除的是顶级的cookie
    VueCookies.set(TOKEN, token, { expires: "8h" });
  }
};
/**
 *  获取token
 */
export const getToken = () => {
  //开发环境删除的是本地的cookie   默认path /
  //  统一全部删除
  if (window.NODE_ENV == "development") {
    // 当前设置
    return  VueCookies.get(TOKEN);
  } else {
    // 先上环境删除的是顶级的cookie
   return  VueCookies.get(TOKEN, { domain: window.location.host });
  }
};
