export const asyncRouterMap = [{
    path: '/',
    name: 'welcome',
    component: () => import ( /* webpackChunkName: "layout" */ "../views/layout/"),
    meta: {title: 'welcome'},
    redirect: '/dashboard/analysis',
    children: []
}]

export const constantRouterMap = [{
    path: "/user",
    redirect: '/user/login',
    name: "login",
    hidden: true,
    component: () => import ( /* webpackChunkName: "login" */ "@/views/login"),
    children: [{
        path: "*", name: "notFound", meta: {
            title: "404", requiresAuth: false
        }, component: () => import ( /* webpackChunkName: "login" */ "@/views/layout/not-found.vue")
    }]
}, {
    path: "/dashboard",
    redirect: '/dashboard/welcome',
    name: "login",
    hidden: true,
    component: () => resolve => require(['@/views/layout/index.vue'], resolve),
    children: [{
        path: "welcome", name: "welcome", meta: {
            title: "welcome", requiresAuth: false
        }, component: () => import ( /* webpackChunkName: "login" */ "@/views/welcome/index.vue")
    }, {
        path: "report", name: "welcome", meta: {
            title: "welcome", requiresAuth: false
        }, component: () => import ( /* webpackChunkName: "login" */ "@/views/welcome/index.vue")
    }]
},]
