<template>
  <div class="help-footer">
    <div class="left">
      <slot></slot>
    </div>
    <div class="right" v-if="len>0">
      <div class="clear-icon">
        <i class="el-icon-circle-close" @click="handleClear"></i>
      </div>
      <div class="bottom">{{len}}/{{selectLimit}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DsnHelpFooter",
  props: {
    len: {
      type: Number,
      default: 0
    },
    selectLimit: {
      type: Number,
      default: 10
    }
  },
  data() {
    return {};
  },
  created() {
    console.log(this.len);
  },
  methods: {
    handleClear() {
      this.$emit("clear");
    }
  }
};
</script>
<style lang="scss">
.help-footer {
  padding-right: 0;
  border: 1px solid #eee;
  position: relative;
  height: 87px;
  margin-bottom: 20px;
  border-radius: 4px;
  box-shadow: 0px 0px 4px rgba(#000, 0.1);
  overflow: hidden;
  .left {
    padding: 8px;
    height: 100%;
    padding-bottom: 10px;
    padding-right: 30px;
    overflow: auto;
    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 4px; /*高宽分别对应横竖滚动条的尺寸*/
      height: 3px;
    }
    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 10px;
      box-shadow: inset 0 0 5px #c0c4cc;
      background: #98a1aa;
    }
  }
  .tip {
    font-size: 12px;
    color: #909399;
    position: absolute;

    text-align: right;
    right: 0;
    bottom: 4px;
    padding-right: 25px;
  }

  .right {
    right: 0;
    top: 0;
    position: absolute;
    padding: 8px 8px 0px 0px;
    .bottom {
      margin-top: 40px;
    }
  }
}
</style>