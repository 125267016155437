<template>
  <!--  删除 -->
  <dsn-button
    type="danger"
    icon="el-icon-delete"
    @click="click"
    :disabled="disabled"
    :loading="loading"
    :round="round"
    :circle="circle"
    :autofocus="autofocus"
  >
    <slot></slot>
  </dsn-button>
</template>
<script>
export default {
  name: "DsnButtonDelete",
  props: {
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      required: false
    },
    plain: {
      type: Boolean,
      default: false
    },
    round: {
      type: Boolean,
      default: false
    },
    circle: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },

    autofocus: {
      type: Boolean,
      default: false
    },
    nativeType: {
      type: String,
      default: "button"
    }
  },
  components: {},
  created() {},
  methods: {
    click: function() {
      this.$emit("click");
    }
  }
};
</script>
