import Vue from "vue";
// import "./lib/rem"
import App from "./App.vue";
import "./config";
import ElementUI from "element-ui";

import VueDraggableResizable from "vue-draggable-resizable";
import {codemirror} from "vue-codemirror";
import "codemirror/lib/codemirror.css";
import VueCookies from "vue-cookies";
import {DEFaLTDIALOG} from "@/constant/";
// optionally import default styles
import "vue-draggable-resizable/dist/VueDraggableResizable.css";
import contentmenu from "v-contextmenu";
import "v-contextmenu/dist/index.css";
import VueContextMenu from "vue-contextmenu";
import elDragDialog from "@/directive/el-drag-dialog";
import VCharts from "v-charts";
import "babel-polyfill"
import './plugins/utils'
import './plugins/table'

import "vue-snotify/styles/simple.css";
import "./assets/css/element-variables.scss";
import "./assets/css/base.css";
import "./assets/css/customer.scss";
import "./assets/css/resetElement.scss";
import "@/assets/icon/iconfont.css";
import "@/components";
import "./registerServiceWorker";
import router from "./router";
// import router from "./router/permissionRouter";
import store from "./store";
import {loadMore} from "./directives";
import i18n from "./i18n/i18n";
//  注册全局mixin
import globalMixins from "./mixins/globalMixins";
import Snotify, {SnotifyPosition} from "vue-snotify";
import adaptive from './directive/el-table'
import ElTreeSelect from 'el-tree-select';
import axios from 'axios'

Vue.use(ElTreeSelect);
Vue.use(adaptive)
Vue.use(elDragDialog)
Vue.mixin(globalMixins);
Vue.use(ElementUI);
Vue.use(codemirror);
Vue.use(VueCookies);
Vue.use(loadMore);
Vue.use(contentmenu);
Vue.use(VueContextMenu);
Vue.use(VCharts);

//账号/密码 - Admin1/123  17354365017/lyq123456

import hasPermission from '@/until/permission/hasPermission'
Vue.use(hasPermission)
Vue.component("vue-draggable-resizable", VueDraggableResizable);
const options = {
    toast: {
        position: SnotifyPosition.rightTop,
        timeout: 3000,
    },
};

Vue.use(Snotify, options);
Vue.config.productionTip = false;


Vue.prototype.$axios = axios;
new Vue({
    provide: {
        defaltDialogWidth: DEFaLTDIALOG, //默认的宽度
    },
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount("#app");
