const equipmentListEdit = {
    // 初始化状态
    state: {
        equipmentList: []
    }, // 编写动作
    mutations: {
        EQUIPMENTLIST(state, data) {
            state.equipmentList = data;
        }
    }, // 取值
    getters: {
        equipmentList: function (state) {
            return state.equipmentList;
        }
    }, // 创建驱动，可异步
    actions: {
        change2({commit}, data) {
            commit("EQUIPMENTLIST", data);
        }
    }
};

export default equipmentListEdit;
