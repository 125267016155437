<template>
  <!--  重置 -->
  <dsn-button
    type
    plain
    class="reset-button"
    icon="el-icon-refresh"
    :disabled="disabled"
    :loading="loading"
    :round="round"
    :circle="circle"
    :autofocus="autofocus"
    @click="click"
  >
    <slot></slot>
  </dsn-button>
</template>
<script>
export default {
  name: "DsnButtonResert",
  props: {
    // 类型
    // type   primary / success / warning / danger / info / text

    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    },
    plain: {
      type: Boolean,
      default: false
    },
    round: {
      type: Boolean,
      default: false
    },
    circle: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },

    autofocus: {
      type: Boolean,
      default: false
    },
    nativeType: {
      type: String,
      default: "button"
    }
  },
  methods: {
    click: function() {
      this.$emit("click");
    }
  }
};
</script>

<style lang="scss">
.reset-button {
  border: 1px solid #0486fe;
  color: #0486fe;
}
</style>
