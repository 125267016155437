const storageListEdit = {
    // 初始化状态
    state: {
        storageList: []
    }, // 编写动作
    mutations: {
        STORAGELIST(state, data) {
            state.storageList = data;
        }
    }, // 取值
    getters: {
        storageList: function (state) {
            return state.storageList;
        }
    }, // 创建驱动，可异步
    actions: {
        change2({commit}, data) {
            commit("STORAGELIST", data);
        }
    }
};

export default storageListEdit;
