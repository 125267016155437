<template>
  <!-- 可折叠的面板 -->
  <div class="leftfoldable-panel">
    <div class="content" :style="`width:{width}px`" :class="toggleClass">
      <div class="ope" v-show="showFoldable"  @click="toggleOpenOrClose">
        <i class="iconfont icon-zhankai " v-show="isClose" style="color:#409eff;font-size:24px"></i>
        <i class="iconfont icon-shouqi" v-show="!isClose" style="color:#409eff;font-size:24px;"></i>
      </div>
      <div class="left-pannel" ref="content">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "LeftPanel",
  data() {
    return {
      width: 30,
      isClose: true
    };
  },
  computed: {
    showFoldable() {
      return Number(this.width) > 29 ? true : false;
    },
    toggleClass() {
      if (this.isClose) {
        return "";
      }
      return "open";
    }
  },
  methods: {
    toggleOpenOrClose() {
      this.isClose = !this.isClose;
      this.$emit("changeIsClose",this.isClose)
    }
  },
  mounted() {
    const children = this.$refs["content"].children;
    if (children.length === 1) {
      this.height = children[0].offsetHeight;
    }
  }
};
</script>

<style lang="scss">
.leftfoldable-panel {
  padding: 16px 0 24px 0;
  background: #fff;
  .content {
    transition: all 0.3 ease-in-out;
    overflow: hidden;
    width: 32px;
    &{
      display: flex;
    }
    &.open {
      overflow: visible;
      width: auto;
      display: flex;
      // & > i {
      //   font-size: 30px;
      // }
    }

    .left-pannel {
    }
  }
  .ope {
    margin: auto;
    transform:rotate(90deg);
    // background-color:red;
    // position: absolute;
    // bottom: 0;
    // left: 0;
    // width: 100%;
    // height: 32px;
    // text-align: center;
  }
}
</style>