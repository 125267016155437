<template>
  <!--  编辑 -->
  <dsn-button
    type="primary"
    icon="iconfont icon-baocun"
    class="save-button"
    @click="click"
    :size="size"
    :disabled="disabled"
    :loading="loading"
    :round="round"
    :circle="circle"
    :autofocus="autofocus"
  >
    <slot></slot>
  </dsn-button>
</template>
<script>
export default {
  name: "DsnButtonSave",
  props: {
    // 尺寸
    // size {mediun,small,mini}
    size: {
      type: String,
      default: "small"
    },
    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      required: false
    },
    plain: {
      type: Boolean,
      default: false
    },
    round: {
      type: Boolean,
      default: false
    },
    circle: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },

    autofocus: {
      type: Boolean,
      default: false
    },
    nativeType: {
      type: String,
      default: "button"
    }
  },
  components: {},
  created() {},
  methods: {
    click: function() {
      this.$emit("click");
    }
  }
};
</script>

<style lang="scss">
.save-button {
  i {
    font-size: 10px;
    margin-right: 5px;
  }
}
</style>