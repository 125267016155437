export default {
    path: "/baseSettings", name: "basicSettings", meta: {
        requiresAuth: true, level: 0,
    }, component: () => import(/* webpackChunkName: "layout" */ "@/views/layout/"), children: [{
        path: "equipmentCategory", name: "equipmentCategory", meta: {
            titleI18n: "categories.menus.list", title: "设备分类", requiresAuth: true, level: 1,
        }, component: () => import(
            /* webpackChunkName: "equipmentCategory" */ "@/views/mes/basic_settings/equipment_category/index"
            ),
    }, {
        path: "equipmentCategoryAdd", name: "equipmentCategoryAdd", meta: {
            titleI18n: "categories.menus.add", title: "设备分类-添加", requiresAuth: true, level: 1,
        }, component: () => import(
            /* webpackChunkName: "equipmentCategory" */ "@/views/mes/basic_settings/equipment_category/components/categoryAdd"
            ),
    }, {
        path: "equipmentCategoryEdit", name: "equipmentCategoryEdit", meta: {
            titleI18n: "categories.menus.edit", title: "设备分类-编辑", requiresAuth: true, level: 1,
        }, component: () => import(
            /* webpackChunkName: "equipmentCategory" */ "@/views/mes/basic_settings/equipment_category/components/categoryEdit"
            ),
    }

        , {
            path: "equipmentScrap", name: "equipmentScrap", meta: {
                title: "设备设施报废流程", requiresAuth: true, level: 1,
            }, component: () => import(
                /* webpackChunkName: "deviceType" */ "@/views/mes/business/equipment_scrap/index"
                ),
        }, {
            path: "equipmentStockRefSettings", name: "equipmentStockRefSettings", meta: {
                titleI18n: "storage.menus.list", title: "储位管理", requiresAuth: true, level: 1,
            }, component: () => import(
                /* webpackChunkName: "deviceType" */ "@/views/mes/basic_settings/equipments_position_ref_settings/index"
                ),
        }, {
            path: "equipmentStockRefSettingsEdit", name: "equipmentStockRefSettingsEdit", meta: {
                titleI18n: "storage.menus.edit", title: "储位编辑", requiresAuth: true, level: 1,
            }, component: () => import(
                /* webpackChunkName: "deviceType" */ "@/views/mes/basic_settings/equipments_position_ref_settings/components/positionRefEdit"
                ),
        }, {
            path: "equipmentStockRefSettingsAdd", name: "equipmentStockRefSettingsAdd", meta: {
                titleI18n: "storage.menus.add", title: "储位添加", requiresAuth: true, level: 1,
            }, component: () => import(
                /* webpackChunkName: "deviceType" */ "@/views/mes/basic_settings/equipments_position_ref_settings/components/positionRefAdd"
                ),
        }, {
            path: "userList", name: "userList", meta: {
                titleI18n: "users.menus.list", title: "用户管理", requiresAuth: true, level: 1,
            }, component: () => import(
                /* webpackChunkName: "userList" */ "@/views/mes/user/users/index"
                ),
        }, {
            path: "userAdd", name: "userAdd", meta: {
                title: "用户管理-添加用户", requiresAuth: true, level: 1,
            }, component: () => import(
                /* webpackChunkName: "userList" */ "@/views/mes/user/users/components/userAdd"
                ),
        }, {
            path: "userEdit", name: "userEdit", meta: {
                title: "用户管理-修改用户", requiresAuth: true, level: 1,
            }, component: () => import(
                /* webpackChunkName: "userList" */ "@/views/mes/user/users/components/userEdit"
                ),
        }, {
            path: "brandList", name: "brandList", meta: {
                title: "品牌管理", requiresAuth: true, level: 1,
            }, component: () => import(
                /* webpackChunkName: "brand" */ "@/views/mes/basic_settings/brand/index"
                ),
        }, {
            path: "brandAdd", name: "brandAdd", meta: {
                title: "品牌管理-添加", requiresAuth: true, level: 1,
            }, component: () => import(
                /* webpackChunkName: "brand" */ "@/views/mes/basic_settings/brand/components/brandAdd"
                ),
        }, {
            path: "brandEdit", name: "brandEdit", meta: {
                title: "品牌管理-修改", requiresAuth: true, level: 1,
            }, component: () => import(
                /* webpackChunkName: "brand" */ "@/views/mes/basic_settings/brand/components/brandEdit"
                ),
        },

        {
            path: "departmentList", name: "departmentList", meta: {
                title: "部门管理", requiresAuth: true, level: 1,
            }, component: () => import(
                /* webpackChunkName: "departmentList" */ "@/views/mes/user/department/index.vue"
                ),
        }, {
            path: "departmentAdd", name: "departmentAdd", meta: {
                title: "部门添加", requiresAuth: true, level: 1,
            }, component: () => import(
                /* webpackChunkName: "departmentList" */ "@/views/mes/user/department/components/departmentAdd.vue"
                ),
        }, {
            path: "departmentEdit", name: "departmentEdit", meta: {
                title: "部门添加", requiresAuth: true, level: 1,
            }, component: () => import(
                /* webpackChunkName: "departmentList" */ "@/views/mes/user/department/components/departmentEdit.vue"
                ),
        },
        {
            path: "roleList", name: "roleList", meta: {
                title: "角色管理", requiresAuth: true, level: 1,
            }, component: () => import(
                /* webpackChunkName: "roles" */ "@/views/mes/user/role/index"
                ),
        },
        {
            path: "rolePermissionAssign", name: "rolePermissionAssign", meta: {
                title: "角色管理-新增", requiresAuth: true, level: 1,
            }, component: () => import(
                /* webpackChunkName: "roles" */ "@/views/mes/user/role/components/rolePermissionAssign"
                ),
        },
        {
            path: "roleUserAssign", name: "roleUserAssign", meta: {
                title: "角色管理-修改", requiresAuth: true, level: 1,
            }, component: () => import(
                /* webpackChunkName: "roles" */ "@/views/mes/user/role/components/roleUserAssign"
                ),
        },

    ],
};
