const equipmentCategoryListEdit = {
    // 初始化状态
    state: {
        equipmentCategoryList: []
    }, // 编写动作
    mutations: {
        EQUIPMENTCATEGORYLIST(state, data) {
            state.equipmentCategoryList = data;
        }
    }, // 取值
    getters: {
        equipmentCategoryList: function (state) {
            return state.equipmentCategoryList;
        }
    }, // 创建驱动，可异步
    actions: {
        change2({commit}, data) {
            commit("EQUIPMENTLIST", data);
        }
    }
};

export default equipmentCategoryListEdit;
