<template>
	<div>
		<div style="display: flex;width: 100%;">
			<!-- 获取数据 -->
			<!-- <div class="top">
				<span class="title">{{$t('home.title[0]')}}</span>
				<span class="value">9,999件</span>
			</div>
			<div class="top">
				<span class="title">{{$t('home.title[1]')}}</span>
				<span class="value">26天</span>
			</div>
			<div class="top">
				<span class="title">{{$t('home.title[2]')}}</span>
				<span class="value">9,999件</span>
			</div>
			<div class="top">
				<span class="title">{{$t('home.title[3]')}}</span>
				<span class="value">9,999件</span>
			</div> -->
		</div>
		<div style="display: flex;width: 100%;height: 500px;">
<!--			<div id="daysOrder" class="echarts"></div>-->
<!--			<div id="daysSum" style="width:80%;height: 600px;margin-top: 50px;"></div>-->
		</div>
	</div>
</template>

<script>
import * as echarts from 'echarts';
import { mapActions } from "vuex";
export default {
  name: "welcome",
  data() {
    return {};
  },
  mounted() {
  	this.loadGraph();
  },
  methods: {
  	loadGraph() {
  		var chartDom = document.getElementById('daysOrder');
  		var indentChart = echarts.init(chartDom);
  		indentChart.setOption({
  			title: {
  				text: this.$t('home.title[4]'),
  				left: 'center'
  			},
  			// tooltip: {
  			// 	trigger: 'item'
  			// },
  			// legend: {
  			// 	orient: 'vertical',
  			// 	left: 'left'
  			// },
			color:['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
  			series: [

  				{
  					name: 'Access From',
  					type: 'pie',
					itemStyle: {
						borderRadius: 10,
						borderColor: '#fff',
						borderWidth: 1
					  },
  					data: [//获取数据
  						{
  							value: 1048,
  							name: '品牌A'
  						},
  						{
  							value: 735,
  							name: '品牌B'
  						},
  						{
  							value: 580,
  							name: '品牌C'
  						},
  						{
  							value: 484,
  							name: '品牌D'
  						},
  						{
  							value: 300,
  							name: '品牌E'
  						}
  					],
  					radius: '75%',
  					center: ['50%', '60%'], // 这个属性可以调整图像的位置，左面所示为中心
  					label: {
  						normal: {
  							show: true,
  							position: 'inner', // 数值显示在内部
  							formatter: '{b},{d}%' // 格式化数值百分比输出
  						}
  					},
  					emphasis: {
  						itemStyle: {
  							shadowBlur: 10,
  							shadowOffsetX: 0,
  							shadowColor: 'rgba(0, 0, 0, 0.5)'
  						}
  					}
  				}
  			]
  		});
  		var daysSum = document.getElementById('daysSum');
  		var DaysSum = echarts.init(daysSum);
  		DaysSum.setOption({
  			title: {
  				text: this.$t('home.title[5]'),
  				left: 'center'
  			},
			xAxis: [{
				type: 'category',
				// data: ['9/1', '9/2', '9/3', '9/4', '9/5', '9/6', '9/7','9/8', '9/9', '9/10', '9/5', '9/6', '9/7', '9/8','9/2', '9/3', '9/4', '9/5', '9/6', '9/7', '9/8','9/2', '9/3', '9/4', '9/5', '9/6', '9/7', '9/8','9/7', '9/8'],
				data: ['9/1', '9/2', '9/3', '9/4', '9/5', '9/6', '9/7','9/8', '9/9', '9/10', '9/11', '9/12', '9/13', '9/14','9/15'],
				// nameTextStyle: {
				// 	overflow: "breakAll",
				// 	width: 100,
				// },
				axisTick: {
					alignWithLabel: true
				},
				axisLabel: {
					fontSize:'30',
					fontFamily:'微软雅黑',
					show: true,
					interval: 0,
					rotate: 45,
				},

			}],
  			yAxis: [{
  				type: 'value',
				show: true,
				axisLabel : {
					formatter: function(){
						  return "";
					}
				}
  			}],
  			series: [
  				{
  					label: {
  						show: true,
  						position: 'top'
  					},
  					data: [300,290,280,240,230,180,160,150,120,120,110,80,60,50,20],//获取数据
  					type: 'bar',
  					// showBackground: true,
  					color: new echarts.graphic.LinearGradient(
  						0,
  						1,
  						0,
  						0,
  						[
  							{
  								offset: 0,
  								color: '#0097e2' // 0% 处的颜色
  							},
  							{
  								offset: 0.5,
  								color: '#00a5f1' // 100% 处的颜色
  							},
  							{
  								offset: 1,
  								color: '#00aaff' // 100% 处的颜色
  							}
  						],
  						false
  					),

  					backgroundStyle: {
  						color: 'rgba(180, 180, 180, 0.2)'
  					}
  				}
  			]
  		});
  	},
	...mapActions(["getUserInfo"])
  },
};
</script>
<style scoped>
.top {
	margin: 0px 40px;
	width: 350px;
	height: 150px;
	background-color: #55aaff;
	border-radius: 30px;
	font-size: 40px;
	color: white;
}
.top .title {
	margin: 20px 10px;
	font-size: 20px;
	color: white;
	font-weight: bold;
}
.top .value {
	margin: -10px 0px;
	float: right;
	font-size: 60px;
	margin-right: 20px;
}
.echarts {
	width: 30%;
	height: 100%;
	margin: 50px 0;
}
</style>
