<template>
  <dsn-input
    class="dsn-input-unit"
    :value="nativeValue"
    @input="input"
    :type="type"
    @focus="focusEvent"
    @change="change"
    @blur="blurEvent"
    :clearable="false"
    :placeholder="placeholder"
    :max="max"
    :min="min"
    :maxlength="maxlength"
    :minlength="minlength"
    :disabled="disabled"
    :readonly="readonly"
    ref="input"
  >
    <template slot="suffix">
      <slot name="suffix">mm</slot>
    </template>
  </dsn-input>
</template>

<script>
export default {
  name: "DsnInputUnit",
  computed: {
    showValue() {
      return this.nativeValue + this.company;
    },
    nativeValue() {
      return this.value === null || this.value === undefined
        ? ""
        : String(this.value);
    }
  },
  model: {
    prop: "value",
    event: "input"
  },

  props: {
    value: {
      type: [String, Number],
      required: false
    },
    placeholder: {
      type: String,
      required: false
    },
    company: {
      type: String,
      default: "px"
    },
    type: {
      type: String,
      default: "Text"
    },
    max: {
      type: [String, Number],
      required: false
    },
    min: {
      type: [String, Number],
      required: false
    },
    minlength: {
      type: [Number],
      required: false
    },
    maxlength: {
      type: [Number],
      required: false
    },
    disabled: {
      type: Boolean,
      required: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },

  methods: {
    input(str) {
      str = str.replace(this.company, "");
      this.$emit("input", str);
    },
    change() {
      this.$emit("change", this.nativeValue);
    },
    blurEvent($event) {
      this.$emit("blur", $event);
    },
    focusEvent($event) {
      this.$emit("focus", $event);
    },
    clear() {
      this.$emit("clear");
    },

    //methods
    focus() {
      this.$refs["input"].focus();
    },
    blur() {
      this.$refs["input"].blur();
    },
    select() {
      this.$refs["input"].select();
    }
  }
};
</script>

<style lang="scss">
.dsn-input-unit {
  width: 100%;
  height: 100%;
  &.dsn-input .el-input__inner {
    padding: 0;
    height: 100px;
    height: 100%;
  }
  border: none;
  input {
    border: none;
    padding: 0 0px;
  }

  .el-input__suffix {
    background: transparent;
    color: #666;
    right: 15px;
  }
}
</style>