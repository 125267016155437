import request from "@/service/";


export const addUser = (data) => {
    return request.post(
        `${window.VUE_APP_URL}/api/users`,
        data
    );
}

export const updateUser = (data) => {
    return request.put(
        `${window.VUE_APP_URL}/api/users/${data.id}`,
        data
    );
}


export const getUserById = (id) => {
    return request.get(
        `${window.VUE_APP_URL}/api/users/${id}`,
    );
}

/***
 *
 * @param params
 * @returns {*}
 */
export const getUserList = (params) => {
    return request.get(
        `${window.VUE_APP_URL}/api/users`,
        {
            params: params
        }
    );
}

export const deleteUserById = (id) => {
    return request.delete(
        `${window.VUE_APP_URL}/api/users/${id}`,
    );
}


export const updateUserPwd = (data) => {
    return request.patch(
        `${window.VUE_APP_URL}/api/users/${id}/password`,
    );
}

export const assignUserRole = (data) => {
    return request.put(
        `${window.VUE_APP_URL}/api/users/assignUserRole`,
        data
    );
}

export const revokeUserRole = (data) => {
    return request.put(
        `${window.VUE_APP_URL}/api/users/revokeUserRole`,
        data
    );
}
