/**
 *  常量配置文件
 */

// export const TOKEN = "mcs.sessionId"; // token的键名称
export const TOKEN = "tpm_token"; // token的键名称
export const DEVELOPMENT = "development"; //  开发环境的名字
export const DEFaLTDIALOG = "400px";

// 站位维护start

// 站位维护查询按钮确认  url:/mes/station/findPage method:post
export const BTN_STANDING_MAINTENANCE_QUERY = "btn_standing_maintenance_query";

// 站位维护保存按钮确认  url:/mes/station/save  method:post
export const BTN_STANDING_MAINTENANCE_SAVE = "btn_standing_maintenance_save";

// 站位维护删除弹框确认  url:/mes/station/deleteStationBatch  method:post
export const BTN_STANDING_MAINTENANCE_DELETE =
    "btn_standing_maintenance_delete";

// 站位维护导入按钮确认  url:/mes/station/station/import  method:post
export const BTN_STANDING_MAINTENANCE_IMPORT =
    "btn_standing_maintenance_import";

// 站位维护导入模板下载  url:/mes/station/station/template
export const BTN_STANDING_MAINTENANCE_IMPORT_TEMPLATE =
    "btn_standing_maintenance_template";

// 站位维护根据查询条件导出全部  url::/mes/station/findPage  method:post
export const BTN_STANDING_MAINTENANCE_EXPORT =
    "btn_standing_maintenance_export";

// 站位维护end

// 上岗证维护start

// 上岗证维护查询按钮确认  url:/cert/listCert method:get
export const BTN_WORKING_CERTIFICATE_QUERY = "btn_working_certificate_query";

// 上岗证维护保存按钮确认  url:/cert/save  method:post
export const BTN_WORKING_CERTIFICATE_SAVE = "btn_working_certificate_save";

// 上岗证维护删除弹框确认  url:/cert/delete  method:post
export const BTN_WORKING_CERTIFICATE_DELETE = "btn_working_certificate_delete";

// 上岗证维护根据查询条件导出全部   url:/cert/listCert method:get
export const BTN_WORKING_CERTIFICATE_EXPORT = "btn_working_certificate_export";

// 上岗证维护end

// 通用代码定义start

// 通用代码定义查询按钮确认  url:/mes/generalCodeService/findGeneralCode method:get
export const BTN_GENERIC_CODE_DEFINITION_QUERY =
    "btn_generic_code_definition_query";

// 通用代码定义保存按钮确认  url:/mes/generalCodeService/saveGeneralCode  method:post
export const BTN_GENERIC_CODE_DEFINITION_SAVE =
    "btn_generic_code_definition_save";

// 通用代码定义删除通用代码弹框确认  url:/mes/generalCodeService/deleteGeneralCode  method:delete
export const BTN_GENERIC_CODE_DEFINITION_DELETE =
    "btn_generic_code_definition_delete";

// 通用代码定义新增
export const BTN_GENERIC_CODE_DEFINITION_ADD =
    "btn_generic_code_definition_add";

// 通用代码定义编辑
export const BTN_GENERIC_CODE_DEFINITION_UPDATE =
    "btn_generic_code_definition_update";

// 通用代码定义根据代码类型，（代码）模糊查询通用代码   url:/mes/generalCodeService/findRecord  method:get
export const BTN_GENERIC_CODE_DEFINITION_QUERY_CODE_ICON =
    "btn_generic_code_definition_query_code_icon";

// 通用代码定义根据通用代码名（字段）从字段表模糊查询字段信息   url:/mes/generalCodeService/findField  method:get
export const BTN_GENERIC_CODE_DEFINITION_QUERY_FIELD_ICON =
    "btn_generic_code_definition_query_field_icon";

// 通用代码定义end

// 通用代码数据start

// 通用代码数据查询按钮确认  url:/mes/generalCodeDataService/findGeneralCode method:post
export const BTN_GENERIC_CODE_DATA_QUERY = "btn_generic_code_data_query";

// 通用代码数据保存按钮确认  url:/mes/generalCodeDataService/saveGeneralCodeData  method:post
export const BTN_GENERIC_CODE_DATA_SAVE = "btn_generic_code_data_save";

// 通用代码数据根据代码类型，（代码）模糊查询通用代码   url:/mes/generalCodeService/findRecord  method:get
export const BTN_GENERIC_CODE_DATA_QUERY_CODE_ICON =
    "btn_generic_code_data_query_code_icon";

// 通用代码数据通过limitGeneralCode和limitGeneralField查询引用的字段信息  url:/generalCodeDataService/findReference  method:get
export const GENERIC_CODE_DATA_QUERY_CITE = "generic_code_data_query_cite";

// 通用代码数据end

// 数据收集start

// 数据收集查询按钮确认  url:/mes/dcGroup/findDcGrouptPage  method:post
export const BTN_DATA_COLLECT_QUERY = "btn_data_collect_query";

// 数据收集保存按钮确认  url:/mes/dcGroup/addDcGroupBatch  method:post
export const BTN_DATA_COLLECT_SAVE = "btn_data_collect_save";

// 数据收集删除弹框确认  url:/mes/dcGroup/deleteDcGroupBatch  method:delete
export const BTN_DATA_COLLECT_DELETE = "btn_data_collect_delete";

// 数据收集根据查询条件导出全部   url:/mes/dcGroup/findDcGrouptPage  method:post
export const BTN_DATA_COLLECT_EXPORT = "btn_data_collect_export";

// 数据收集查询预警事件编号   url:/mes/alarmDef/listAlarm  method:get
export const DATA_COLLECT_ALARM_LIST = "data_collect_alarm_list";

// 数据收集查询参数配置   url:/mes/dcParameterMeasure/findDcParameterMeasureList  method:get
export const DATA_COLLECT_PARAM_LIST = "data_collect_param_list";

// 数据收集查询数据收集配置   url:/mes/dcSetup/findDcSetupList  method:get
export const DATA_COLLECT_SETUP_LIST = "data_collect_setup_list";

// 数据收集end

// 独立数据查询报表start

// 独立数据查询报表查询数据收集结果数据报表按钮确认  url:/mes/dcDataReport/findDcDataPage  method:post
export const BTN_DC_SEARCH_DATA_QUERY = "btn_dc_search_data_query";

// 独立数据查询报表查询数据收集结果数据报表导出全部   url:/mes/dcDataReport/findDcDataPage  method:post
export const BTN_DC_SEARCH_DATA_EXPORT = "btn_dc_search_data_export";

// 独立数据查询报表查询数据收集结果参数数据报表按钮确认  url:/dcDataReport/findDcParamPage  method:post
export const BTN_DC_SEARCH_PARAMS_QUERY = "btn_dc_search_params_query";

// 独立数据查询报表查询数据收集结果参数数据报表导出全部   url:/dcDataReport/findDcParamPage  method:post
export const BTN_DC_SEARCH_PARAMS_EXPORT = "btn_dc_search_params_export";

// 独立数据查询报表end

// 独立数据收集start

// 独立数据收集查询按钮确认  url:/mes/dcData/findDcGroupData  method:post
export const BTN_DC_COLLECT_QUERY = "btn_dc_collect_data_query";

// 独立数据收集校验按钮确认  url:/mes/dcData/checkParam  method:post
export const BTN_DC_COLLECT_CHECK = "btn_dc_collect_check";

// 独立数据收集提交按钮确认  url:/mes/dcData/saveDataCollectResult  method:post
export const BTN_DC_COLLECT_SUBMIT = "btn_dc_collect_submit";

// 独立数据收集查询启用的参数列表  url:/mes/dcParameterMeasure/findActiveDcParameterMeasureList  method:get
export const DC_COLLECT_PARAM_LIST = "dc_collect_param_list";

// 独立数据收集end

// LOT弹框公用start

// LOT步骤更改Lot查询，支持模糊查询   url:/mes/LotStepStatusService/searchLotList  method:post
export const BTN_STEP_CHANGE_QUERY_LOT_ICON = "btn_step_change_query_lot_icon";

// LOT步骤更改查询所有物料   url:/material/listAll  method:post
export const BTN_STEP_CHANGE_QUERY_MATERIAL_ICON =
    "btn_step_change_query_material_icon";

// LOT步骤更改查询工艺路线列表: 分页和不分页   url:/mes/router/listRouterPage  method:get
export const BTN_STEP_CHANGE_QUERY_ROUTER_ICON =
    "btn_step_change_query_router_icon";

// LOT步骤更改查询所有工单   url:/mes/shopOrder/findShopOrderList  method:get
export const BTN_STEP_CHANGE_QUERY_ORDER_ICON =
    "btn_step_change_query_order_icon";

// LOT步骤更改分页查询所有工序   url:/operation/findPage  method:get
export const BTN_STEP_CHANGE_QUERY_OPERATION_ICON =
    "btn_step_change_query_operation_icon";

// LOT步骤更改获取所有设备   url:/mes/resource/listAllResource  method:get
export const BTN_STEP_CHANGE_QUERY_RESOURCE_ICON =
    "btn_lot_step_query_resource_icon";

// LOT弹框公用end

// LOT步骤更改start

// LOT步骤更改查询按钮确认  url:/mes/LotStepStatusService/findLotStepStatus  method:post
export const BTN_LOT_STEP_QUERY = "btn_lot_step_query";

// LOT步骤更改Lot状态完成接口按钮  url:/LotStepStatusService/setLotsStatusDone  method:post
export const BTN_LOT_STEP_SET_DONE = "btn_lot_step_set_done";

// LOT步骤更改保存按钮确认  url:/LotStepStatusService/saveLotStepStatus  method:post
export const BTN_LOT_STEP_SAVE = "btn_lot_step_save";

// LOT步骤更改双击表格  url:/mes/LotStepStatusService/findLotStepDetail  method:post
export const BTN_LOT_STEP_CELL_DB_CLICK = "btn_lot_step_cell_db_click";

// LOT步骤更改Lot步骤更改规则获取   url:/LotStepStatusService/getLotStepChangRule  method:get
export const LOT_STEP_CHANGE_RULE = "lot_step_change_rule";

// LOT步骤更改end

// LOT拆分start

// LOT拆分查询按钮确认  url:/mes/splitLot/findLotAtOperation  method:get
export const BTN_LOT_DIVESTITURE_QUERY = "btn_lot_divestiture_query";

// LOT拆分拆分按钮  url:/splitLot/splitLot  method:post
export const BTN_LOT_DIVESTITURE_DIVESTITURE =
    "btn_lot_divestiture_divestiture";

// LOT拆分end

// LOT合并start

// LOT合并查询按钮确认  url:/mes/mergeLot/findLotAtOperation  method:get
export const BTN_LOT_MERGE_QUERY = "btn_lot_merge_query";

// LOT合并添加按钮  url:/mes/mergeLot/addLotAtOperation  method:post
export const BTN_LOT_MERGE_ADD = "btn_lot_merge_add";

// LOT合并合并按钮  url:/mes/mergeLot/mergeLot  method:post
export const BTN_LOT_MERGE_MERGE = "btn_lot_merge_merge";

// LOT合并end

// 站位按钮分配start

// 站位按钮分配查询按钮确认  url:/stationButton/findPage  method:get
export const BTN_STANDING_BUTTON_DISTRIBUTE_QUERY =
    "btn_standing_button_distribute_query";

// 站位按钮分配新增按钮确认  url:/stationButton/create  method:post
export const BTN_STANDING_BUTTON_DISTRIBUTE_ADD =
    "btn_standing_button_distribute_add";

// 站位按钮分配修改按钮确认  url:/stationButton/save  method:post
export const BTN_STANDING_BUTTON_DISTRIBUTE_EDIT =
    "btn_standing_button_distribute_edit";

// 站位按钮分配保存按钮确认  url:/stationButton/create   and  /stationButton/save    method:post
export const BTN_STANDING_BUTTON_DISTRIBUTE_SAVE =
    "btn_standing_button_distribute_save";

// 站位按钮分配获取已分配按钮  url:/mes/stationButton/listAssignedButton  method:get
export const BTN_STANDING_BUTTON_DISTRIBUTE_ASSIGN =
    "btn_standing_button_distribute_assign";

// 站位按钮分配获取未分配按钮  url:/mes/stationButton/listUnassingButton  method:get
export const BTN_STANDING_BUTTON_DISTRIBUTE_UNASSIGN =
    "btn_standing_button_distribute_unassign";

// 站位按钮分配获取产线站位  url:/mes/station/station/import  method:post
export const BTN_STANDING_BUTTON_DISTRIBUTE_QUERY_STATION =
    "btn_standing_button_distribute_query_station";

// 站位按钮分配end

// 物料维护start

// 物料维护查询按钮确认  url /mes/material/findMaterial method:post
export const BTN_MATERIAL_INFO_QUERY = "btn_material_info_query";

// 物料维护新增页面保存确认  url /mes/material/insert  method:post
export const BTN_MATERIAL_INFO_ADD = "btn_material_info_add";

// 物料维护修改页面保存确认  url /mes/material/update  method:post
export const BTN_MATERIAL_INFO_UPDATE = "btn_material_info_update";

// 物料维护删除弹框确认  url /mes/material/delete  method:post
export const BTN_MATERIAL_INFO_DELETE = "btn_material_info_delete";

// 物料维护根据查询条件导出全部(查所有)  url /material/listAll  method:post
export const BTN_MATERIAL_INFO_EXPORT = "btn_material_info_export";

// 物料维护end

// 物料组维护start

// 物料组维护查询按钮确认  url /mes/matGroup/findPage method:post
export const BTN_MATERIAL_GROUP_QUERY = "btn_material_group_query";

// 物料组维护新增 拿到所有物料信息  url /mes/matGroup/getAllDistinct  method:post
export const BTN_MATERIAL_GROUP_ADD = "btn_material_group_add";

// 物料组维护修改/新增后保存 url /mes/matGroup/save   method:post
export const BTN_MATERIAL_GROUP_UPDATE = "btn_material_group_update";

// 物料组维护删除弹框确认  url /mes/material/delete  method:post
export const BTN_MATERIAL_GROUP_DELETE = "btn_material_group_delete";

// 物料组维护根据查询条件导出全部(查所有)  url /mes/matGroup/listALL  method:post
export const BTN_MATERIAL_GROUP_EXPORT = "btn_material_group_export";

// 物料组维护end

// 物料清单维护start

// 物料清单维护查询按钮确认  url /mes/bom/listBom method:get
export const BTN_MATERIAL_LIST_QUERY = "btn_material_list_query";

// 物料清单维护新增 拿到所有物料信息  url /mes/matGroup/getAllDistinct  method:post
export const BTN_MATERIAL_LIST_ADD = "btn_material_list_add";

// 物料清单维护修改 url /mes/material/listAll
export const BTN_MATERIAL_LIST_UPDATE = "btn_material_list_update";

// 物料清单维护修改/新增后保存 url /mes/bom/saveBom   method:post
export const BTN_MATERIAL_LIST_UPDATESAVE = "btn_material_list_updateSave";

// 物料清单维护删除弹框确认  url /mes/bom/delete  method:post
export const BTN_MATERIAL_LIST_DELETE = "btn_material_list_delete";

// 物料清单维护根据查询条件导出全部(查所有)  url /mes/matGroup/listALL  method:post
export const BTN_MATERIAL_LIST_EXPORT = "btn_material_list_export";

// 物料清单维护end

// 工单维护start

// 工单维护查询icon  url /mes/shopOrder/findShopOrderList method:get
export const BTN_WORK_ORDER_QUERYICON = "btn_work_order_queryIcon";

// 工单维护model查询按钮  url /mes/shopOrder/findShopOrder method:get
export const BTN_WORK_ORDER_MODELORDERQUERY = "btn_work_order_modelOrderQuery";

// 工单维护查询按钮确认  url /mes/shopOrder/findShopOrder method:get
export const BTN_WORK_ORDER_QUERY = "btn_work_order_query";

// 工单维护保存 url /mes/shopOrder/saveShopOrder method:post /mes/shopOrder/updateShopOrder method:put
export const BTN_WORK_ORDER_UPDATE = "btn_work_order_update";

// 工单维护删除 url /mes/shopOrder/deleteShopOrderBatch method:delete
export const BTN_WORK_ORDER_DELETE = "btn_work_order_delete";

// 工单维护重置 url /mes/customizedFieldDef/findCustomizedFieldDefList method:get
export const BTN_WORK_ORDER_RESET = "btn_work_order_reset";

// 工单维护物料icon 查所有物料 url /material/findMaterial method:post
export const BTN_WORK_ORDER_MATERIALICON = "btn_work_order_materialIcon";

// 工单维护物料model查询 url /mes/material/findMaterial method:post
export const BTN_WORK_ORDER_MODELMATERIALQUERY =
    "btn_work_order_modelMaterialQuery";

// 工单维护路线icon url /router/listRouterPage method:get
export const BTN_WORK_ORDER_ROUTERICON = "btn_work_order_routerIcon";

// 工单维护路线查询 url /router/getRouter method:get
export const BTN_WORK_ORDER_MODELROUTERQUERY =
    "btn_work_order_modelRouterQuery";

// 工单维护end

// 工单下达

// 工单下达工单查询icon  url /mes/shopOrder/findShopOrderList method:get
export const BTN_ISSUED_QUERYICON = "btn_issued_queryIcon";

// 工单下达model查询按钮  url /mes/shopOrder/findShopOrderList method:get
export const BTN_ISSUED_MODEISSUEDQUERY = "btn_issued_modelIssuedQuery";

// 工单下达获取 url /mes/shopOrder/findReleaseInfo method:get
export const BTN_ISSUED_GET = "btn_issued_get";

// 工单下达下达按钮 url /mes/shopOrder/release method:post
export const BTN_ISSUED_RELEASE = "btn_issued_release";

// 工单下达导出 未调接口
export const BTN_ISSUED_EXPORT = "btn_issued_export";

// 工单下达end

// 主题维护start

// 主题维护分页查询  url /mes/topic/findTopicPage method:get
export const BTN_THEME_SAFEGUARD_QUERY = "btn_theme_safeguard_query";

// 主题维护新增页面保存 url /mes/topic/addTopic  method:post
export const BTN_THEME_SAFEGUARD_ADD = "btn_theme_safeguard_add";

// 主题维护修改页面保存  url /mes/topic/updateTopic  method:put
export const BTN_THEME_SAFEGUARD_UPDATE = "btn_theme_safeguard_update";

// 主题维护删除弹框确认  url /mes/topic/deleteTopicBatch  method:post
export const BTN_THEME_SAFEGUARD_DELETE = "btn_theme_safeguard_delete";

// 主题维护根据查询条件导出全部(查所有)  url /material/listAll  method:post
export const BTN_THEME_SAFEGUARD_EXPORT = "btn_theme_safeguard_export";

// 主题维护end

// 生产操作界面配置 start

// 生产操作界面配置查询  url /mes/podConfig/{podConfigName} method:get
export const BTN_PROCONFIGURATION_QUERY = "btn_proConfiguration_query";

// 生产操作界面配置保存 url /mes/podConfig  method:post
export const BTN_PROCONFIGURATION_SAVE = "btn_proConfiguration_save";

// 生产操作界面配置删除  url /mes/podConfig/{podConfigName}  method:delete
export const BTN_PROCONFIGURATION_DELETE = "btn_proConfiguration_delete";

// 生产操作界面配置 end

// 生产操作界面 start

// 生产操作界面查询  url /mes/podConfig/listPodLot method:get
export const BTN_PRODUCTION_OPERATE_QUERY = "btn_production_operate_query";

// 生产操作界面lot图标 url /mes/lot/listLot  method:get
export const BTN_PRODUCTION_OPERATE_LOTICON = "btn_production_operate_lotIcon";

// 生产操作界面lotmodel查询 url /mes/LotStepStatusService/searchLotList  method:post
export const BTN_PRODUCTION_OPERATE_LOTMODELQUERY =
    "btn_production_operate_lotModelQuery";

// 生产操作界面 end

// lot数量调整界面 start

// lot数量调整界面查询  url /mes/splitLot/findLotAtOperation?lot= method:get
export const BTN_LOT_NUM_QUERY = "btn_lot_num_query";

// lot数量调整界面lot图标 url /mes/lot/listLot  method:get
export const BTN_LOT_NUM_LOTICON = "btn_lot_num_lotIcon";

// lot数量调整界面lotmodel查询 url /mes/LotStepStatusService/searchLotList  method:post
export const BTN_LOT_NUM_LOTMODELQUERY = "btn_lot_num_lotModelQuery";

// lot数量调整界面调整按钮 url /mes/lot/listLot  method:get
export const BTN_LOT_NUM_CHANGENUM = "btn_lot_num_changeNum";

// lot数量调整界面 end

// 包装解包配置start

// 包装解包配置查询按钮确认  url:/mes/packagingConfiguration/findPackagingConfigurationPage  method:get
export const BTN_UNPACK_QUERY = "btn_unpack_query";

// 包装解包配置新增按钮确认  url:/mes/packagingConfiguration/addPackagingConfiguration  method:post
export const BTN_UNPACK_ADD = "btn_unpack_add";

// 包装解包配置修改按钮确认  url:/mes/packagingConfiguration/updatePackagingConfiguration    method:put
export const BTN_UNPACK_UPDATE = "btn_unpack_update";

// 包装解包配置保存按钮确认  url:addPackagingConfiguration   and  updatePackagingConfiguration   method:post  put
export const BTN_UNPACK_SAVE = "btn_unpack_save";

// 包装解包配置删除按钮  url:/mes/packagingConfiguration/deletePackagingConfigurationBatch  method:post
export const BTN_UNPACK_DELETE = "btn_unpack_delete";

// 包装解包配置导出按钮确认  url:/mes/packagingConfiguration/findPackagingConfigurationPage  method:get
export const BTN_UNPACK_EXPORT = "btn_unpack_export";

// 获取所有物料号  url:/mes/material/findMaterial  method:post
export const UNPACK_MATERIAL_LIST = "unpack_material_list";

// 根据物料号查询所有的版本号  url:/material/listMaterialRevByCode  method:get
export const UNPACK_MATERIAL_REV_LIST = "unpack_material_rev_list";

// 包装解包配置end

//
// 标签配置start

// 标签配置查询按钮确认  url:/mes/label/listPage  method:post
export const BTN_TAG_CONFIG_QUERY = "btn_tag_config_query";

// 标签配置新增按钮确认  url:/label/insert  method:post
export const BTN_TAG_CONFIG_ADD = "btn_tag_config_add";

// 标签配置修改按钮确认  url:/label/update  method:post
export const BTN_TAG_CONFIG_UPDATE = "btn_tag_config_update";

// 标签配置删除按钮确认  url:/label/insert  method:post
export const BTN_TAG_CONFIG_DELETE = "btn_tag_config_delete";

// 标签配置导出按钮确认  url:/mes/label/listPage  method:post
export const BTN_TAG_CONFIG_EXPORT = "btn_tag_config_export";

// 标签配置测试打印按钮确认  url:/mes/labelPrinting/printLabel  method:post
export const BTN_TAG_CONFIG_PRINT_LABEL = "btn_tag_config_print_label";

// 标签配置end

// 标签打印start

// 标签打印检索按钮  url:/mes/labelPrinting/searchByLotNo  method:post
export const BTN_TAG_PRINT_QUERY = "btn_tag_print_query";

// 标签打印测试打印按钮确认  url:/mes/labelPrinting/printLabel  method:post
export const BTN_TAG_PRINT_PRINTING = "btn_tag_print_printing";

// 标签打印end

// 工艺路线start

// 工艺路线查询按钮  url:/mes/router/getRouter  method:get
export const BTN_ROUTE_MAINTENANCE_QUERY = "btn_route_maintenance_query";

// 工艺路线保存按钮  url:/mes/router/saveOrUpdateRouter  method:post
export const BTN_ROUTE_MAINTENANCE_SAVE = "btn_route_maintenance_save";

// 工艺路线删除按钮  url:/mes/router/removeRouter  method:post
export const BTN_ROUTE_MAINTENANCE_DELETE = "btn_route_maintenance_delete";

// 工艺路线帮助按钮图标确认  url:/mes/router/listRouterPage  method:get
export const ROUTE_MAINTENANCE_QUERY_ICON = "route_maintenance_query_icon";

// 工艺路线end

// 日志查询start

// 日志查询按钮  url:/mes/router/getRouter  method:get
export const BTN_MES_LOG_QUERY = "btn_mes_log_query";

// 日志查询导出按钮  url:/mes/router/saveOrUpdateRouter  method:post
export const BTN_MES_LOG_EXPORT = "btn_mes_log_export";

// 日志查询end

//用户证明分配
// 用户证明分配查询按钮
export const BTN_CERTUSER_QUERY = "btn_certUser_query";

// 用户证明分配新增按钮确认
export const BTN_CERTUSER_ADD = "btn_certUser_add";

// 用户证明分配修改按钮确认
export const BTN_CERTUSER_UPDATA = "btn_certUser_update";

//用户证明分配删除按钮确认
export const BTN_CERTUSER_DELETE = "btn_certUser_delete";

// 用户证明分配导出按钮
export const BTN_CERTUSER_EXPORT = "btn_certUser_export";

// 预警事件维护查询按钮
export const BTN_ALARMMAINTAIN_QUERY = "btn_alarmMaintain_query";

// 预警事件维护新增按钮确认
export const BTN_ALARMMAINTAIN_ADD = "btn_alarmMaintain_add";

// 预警事件维护修改按钮确认
export const BTN_ALARMMAINTAIN_UPDATA = "btn_alarmMaintain_update";

//预警事件维护删除按钮确认
export const BTN_ALARMMAINTAIN_DELETE = "btn_alarmMaintain_delete";

// 预警事件维护导出按钮
export const BTN_ALARMMAINTAIN_EXPORT = "btn_alarmMaintain_export";

// 预警事件处理未处理事件导出按钮
export const BTN_ALARMDEAL_EXPORT = "btn_alarmDeal_export";

// 预警事件处理 未处理事件查询按钮
export const BTN_ALARMDEA_QUERY = "btn_alarmDeal_query";

// 预警事处理未处理事件确认按钮
export const BTN_ALARMDEA_SAVE = "btn_alarmDeal_save";


// 预警事件处理 已处理事件查询按钮
export const BTN_ALARMDEAS_QUERY = "btn_alarmDeals_query";

// 预警事件处理已处理事件导出按钮
export const BTN_ALARMDEALS_EXPORT = "btn_alarmDeals_export";

//预警事件维护删除按钮确认
export const BTN_ALARMDEALS_DELETE = "btn_alarmDeals_delete";

//预警事件维护初始化按钮确认
export const BTN_ALARMDEALS_INIT = "btn_alarmDeals_init";

//事件涉及SN序列/lot序列
//预警事件涉及SN序列/lot序列查询
export const BTN_ALARMDEASS_QUERY = "btn_alarmDealss_query";

// 预警事件涉及SN序列/lot序列导出
export const BTN_ALARMDEALSS_EXPORT = "btn_alarmDealss_export";

// 预警事件分组start
// 查询预警事件分组信息按钮  url:/mes/alarmGroup/listPage method:post
export const BTN_ALARMGROUP_QUERY = "btn_alarmGroup_query";

// 查询预警事件分组新增按钮确认  url:/mes/alarmGroup/insert  method:post
export const BTN_ALARMGROUP_ADD = "btn_alarmGroup_add";

// 查询预警事件分组修改按钮确认  url:/mes/alarmGroup/save  method:post
export const BTN_ALARMGROUP_UPDATE = "btn_alarmGroup_update";

// 查询预警事件分组删除按钮确认  url:/mes/alarmGroup/deleteBatch method:post
export const BTN_ALARMGROUP_DELETE = "btn_alarmGroup_delete";

// 查询预警事件分组导出按钮  url:/mes/alarmGroup/listPage method:post
export const BTN_ALARMGROUP_EXPORT = "btn_alarmGroup_export";
// 预警事件分组end

// 包装_解包作业start
// 包装_解包作业完成装入按钮  url:/mes/container/saveContainerInfo method:post
export const BTN_PACKWORK_SAVECONTAINERINFO = "btn_packWork_saveContainerInfo";

// 包装_解包作业重置按钮  url://mes/containerRelationship/resetContainerRelationship  method:get
export const BTN_PACKWORK_RESETCONTTAINERRELATIONSHIP = "btn_packWork_resetContainerRelationship";

// 包装_解包作业打印按钮  url:/mes/labelPrinting/printLabel  method:post
export const BTN_PACKWORK_PRINTLABEL = "btn_packWork_printLabel";

// 包装_解包作业导出按钮
export const BTN_PACKWORK_EXPORT = "btn_packWork_export";

// 包装_解包作业打印配置筛选符合条件标签Id  url::/mes/labelPrinting/searchLabelIdListByMatAndType method:post
export const BTN_PACKWORK_SEARCHLABELIDLISTBYMATANDTYPE = "btn_packWork_searchLabelIdListByMatAndType";

// 包装_解包作业根据容器编号，LOT/被容器编号，物料号，包装层级查询基础信息，清单信息以及校验数据 url::/mes/mes/container/findContainerList method:post
export const ALL_PACKWORK_FINDCONTAINERLIST = "btn_packWork_findContainerList";

// 包装_解包作业打印配置获取可打印的设备  url::/mes/labelPrinting/getPrintDevicesAvailable method:post
export const ALL_PACKWORK_GETPRINDEVICESAVAILABLE = "btn_packWork_getPrintDevicesAvailable";

// 包装_解包作业end

// 设备维护查询按钮
export const BTN_DEVICEMAINTENANCE_QUERY = "btn_deviceMaintenance_query";

// 设备维护护新增按钮确认
export const BTN_DEVICEMAINTENANCE_ADD = "btn_deviceMaintenance_add";

// 设备维护修改按钮确认
export const BTN_DEVICEMAINTENANCE_UPDATA = "btn_deviceMaintenance_update";

//设备维护删除按钮确认
export const BTN_DEVICEMAINTENANCE_DELETE = "btn_deviceMaintenance_delete";

// 设备维护导出按钮
export const BTN_DEVICEMAINTENANCE_EXPORT = "btn_deviceMaintenance_export";

// 设备类型查询按钮
export const BTN_DEVICETYPE_QUERY = "btn_deviceType_query";

// 设备类型新增按钮确认
export const BTN_DEVICETYPE_ADD = "btn_deviceType_add";

// 设备类型修改按钮确认
export const BTN_DEVICETYPE_UPDATA = "btn_deviceType_update";

//设备类型删除按钮确认
export const BTN_DEVICETYPE_DELETE = "btn_deviceType_delete";

// 设备类型导出按钮
export const BTN_DEVICETYPE_EXPORT = "btn_deviceType_export";

// 设备保养导出按钮
export const BTN_DEVICEUPKEEP_EXPORT = "btn_deviceUpkeep_export";

// 设备保养查询按钮
export const BTN_DEVICEUPKEEP_QUERY = "btn_deviceUpkeep_query";

// 设备保养确定保养按钮确认
export const BTN_DEVICEUPKEEP_SURE = "btn_deviceUpkeep_sure";

// 不合格代码维护start
// 不合格代码维护查询按钮  url:/mes/nc_code/listNcCode method:get
export const BTN_NC_CODE_LISTNCCOD_QUERY = "btn_nc_code_listNcCod_query";

// 不合格代码维护保存按钮确认  url:/mes/nc_code/save  method:post
export const BTN_NC_CODE_LISTNCCOD_SAVE = "btn_nc_code_listNcCod_save";

// 不合格代码维护删除弹框确认  url:/mes/nc_code/deleteNcCode method:post
export const BTN_NC_CODE_LISTNCCOD_DELETE = "btn_nc_code_listNcCod_delete";

//不合格代码维护根据查询条件导出全部  url::/mes/station/findPage  method:post
export const BTN_NC_CODE_LISTNCCOD_EXPORT = "btn_nc_code_listNcCod_export";
// 不合格代码维护end
// 不合格代码组维护start
// 不合格代码组维护查询按钮  url:/mes/nc_group/findPage method:get
export const BTN_NC_GROUP_QUERY = "btn_nc_group_query";

// 不合格代码组维护保存按钮确认  url:/mes/nc_group/save  method:post
export const BTN_NC_GROUP_SAVE = "btn_nc_group_save";

// 不合格代码组维护删除弹框确认  url:/mes/nc_group/delete method:post
export const BTN_NC_GROUP_DELETE = "btn_nc_group_delete";

//不合格代码组维护根据查询条件导出全部  url::/mes/nc_group/findPage  method:post
export const BTN_NC_GROUP_EXPORT = "btn_nc_group_export";
// 不合格代码组维护end

// 不合格记录维护start
// 不合格记录维护查询按钮  url:/mes/nc_data/findPage method:get
export const BTN_NC_DATA_QUERY = "btn_nc_data_query";

// 不合格记录维护保存按钮确认  url:/mes/nc_data/saveNcData  method:post
export const BTN_NC_DATA_SAVEncdata = "btn_nc_data_saveNcData";

// 不合格记录维护关闭按钮确认  url:/mes/nc_data/closeNcData method:post
export const BTN_NC_DATA_CLOSENCDATA = "btn_nc_data_closeNcData";

// 不合格记录维护end

// 自定义字段维护 start
// 自定义字段维护查询信息按钮  url:/mes/customizedFieldDef/findCustomizedFieldDefList method:get
export const BTN_CUSTOMIZE_QUERY = "btn_customize_query";

// 自定义字段维护 新增按钮确认
export const BTN_CUSTOMIZE_ADD = "btn_customize_add";

// 自定义字段维护 修改按钮确认
export const BTN_CUSTOMIZE_UPDATA = "btn_customize_updata";

//自定义字段维护 删除按钮确认
export const BTN_CUSTOMIZE_DELETE = "btn_customize_delete";

//自定义字段维护 删除按钮确认
export const BTN_CUSTOMIZE_SAVE = "btn_customize_save";


// 自定义字段维护 end


// 编码规则start
// 编码规则查询按钮
export const BTN_NEXTNUMBER_QUERY = "btn_nextNumber_query";

// 编码规则新增按钮确认
export const BTN_NEXTNUMBER_ADD = "btn_nextNumber_add";

// 编码规则修改按钮确认
export const BTN_NEXTNUMBER_UPDATA = "btn_nextNumber_update";

// 编码规则删除按钮确认
export const BTN_NEXTNUMBER_DELETE = "btn_nextNumber_delete";

// 编码规保存按钮确认
export const BTN_NEXTNUMBER_SAVE = "btn_nextNumber_save";

// 编码规则导出按钮
export const BTN_NEXTNUMBER_EXPORT = "btn_nextNumber_export";
// 编码规则end


//菜单作业维护
// 菜单作业维护查询按钮
export const BTN_OPERATION_QUERY = "btn_operation_query";

// 菜单作业维护新增按钮确认
export const BTN_OPERATION_ADD = "btn_operation_add";

//菜单作业维护修改按钮确认
export const BTN_OPERATION_UPDATA = "btn_operation_update";

//菜单作业维护删除按钮确认
export const BTN_OPERATION_DELETE = "btn_operation_delete";
//导出
export const BTN_OPERATION_EXPORT = "btn_operation_export";


// 工序维护start
// 工序维护查询按钮
export const BTN_OPERATIONMAINTAIN_QUERY = "btn_operationMaintain_query";

// 工序维护新增按钮确认
export const BTN_OPERATIONMAINTAIN_ADD = "btn_operationMaintain_add";

// 工序维护修改按钮确认
export const BTN_OPERATIONMAINTAIN_UPDATA = "btn_operationMaintain_update";

//工序维护删除按钮确认
export const BTN_OPERATIONMAINTAIN_DELETE = "btn_operationMaintain_delete";

// 工序维护导出按钮
export const BTN_OPERATIONMAINTAIN_EXPORT = "btn_operationMaintain_export";
// 工序维护end

// 工序与站位关系维护start
// 查询按钮
export const BTN_OPERATIONSTATION_QUERY = "btn_operationStation_query";

// 新增按钮确认
export const BTN_OPERATIONSTATION_ADD = "btn_operationStation_add";
// 修改按钮确认
export const BTN_OPERATIONSTATION_UPDATA = "btn_operationStation_updata";
// 导出按钮
export const BTN_OPERATIONSTATION_EXPORT = "btn_operationStation_export";
// 工序与站位关系维护end

//报废/删除 start
// 删除/恢复查询按钮  url:/mes/scrapAndDelete/findPage method:post
export const BTN_DELETE_RECOVER_QUERY = "btn_delete_recover_query";

// 删除/恢复删除标记按钮  url:/mes/scrapAndDelete/delete method:post
export const BTN_DELETE_RECOVER_DELETE = "btn_delete_recover_delete";

// 删除/恢复恢复标记按钮  url:/mes/scrapAndDelete/unDelete method:post
export const BTN_DELETE_RECOVER_UNDELETE = "btn_delete_recover_undelete";

// 删除/恢复导出1按钮  url:/mes/scrapAndDelete/unDelete method:post
export const BTN_DELETE_RECOVER_EXPORT = "btn_delete_recover_export";

// 报废/恢复查询按钮  url:/mes/scrapAndDelete/findPage method:post
export const BTN_SCRAP_RECOVER_QUERY = "btn_scrap_recover_query";

// 报废/恢复报废标记按钮  url:/mes/scrapAndDelete/scrap method:post
export const BTN_SCRAP_RECOVER_SCRAP = "btn_scrap_recover_scrap";


// 报废/恢复恢复标记按钮  url:/mes/scrapAndDelete/unScrap method:post
export const BTN_SCRAP_RECOVER_UNSCRAP = "btn_scrap_recover_unscrap";

// 报废/恢复导出1按钮  url:/mes/scrapAndDelete/unDelete method:post
export const BTN_SCRAP_RECOVER_EXPORT = "btn_scrap_recover_export";

//报废/删除 end

// 工作中心start
// 工作中心查询按钮
export const BTN_WORKCENTER_QUERY = "btn_workCenter_query";

// 工作中心新增按钮确认
export const BTN_WORKCENTER_ADD = "btn_workCenter_add";

// 工作中心修改按钮确认
export const BTN_WORKCENTER_UPDATA = "btn_workCenter_update";

// 工作中心删除按钮确认
export const BTN_WORKCENTER_DELETE = "btn_workCenter_delete";

// 工作中心导出按钮
export const BTN_WORKCENTER_EXPORT = "btn_workCenter_export";
// 工作中心end
