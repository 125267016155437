const roleListEdit = {
    // 初始化状态
    state: {
        roleEditList: []
    },
    // 编写动作
    mutations: {
        ROLEEDITLIST(state, data) {
            state.roleEditList = data;
        }
    },
    // 取值
    getters: {
        roleEditList: function(state) {
            return state.roleEditList;
        }
    },
    // 创建驱动，可异步
    actions: {
        change2({ commit }, data) {
            commit("ROLEEDITLIST", data);
        }
    }
};

export default roleListEdit;
