<template>
  <!-- 可折叠的面板 -->
  <div class="foldable-panel">
    <div class="content" :style="`height:{height}px`" :class="toggleClass">
      <div class="left" ref="content">
        <slot></slot>
      </div>
      <div class="right">
        <slot name="right"></slot>
      </div>
    </div>
    <div class="ope" v-show="showFoldable" @click="toggleOpenOrClose">
      <i class="el-icon-caret-bottom" v-show="isClose"></i>
      <i class="el-icon-caret-top" v-show="!isClose"></i>
    </div>
  </div>
</template>
<script>
export default {
  name: "FoldablePanel",
  data() {
    return {
      height: 40,
      isClose: true
    };
  },
  computed: {
    showFoldable() {
      return Number(this.height) > 40 ? true : false;
    },

    toggleClass() {
      if (this.isClose) {
        return "";
      }
      return "open";
    }
  },
  methods: {
    toggleOpenOrClose() {
      this.isClose = !this.isClose;
    }
  },
  mounted() {
    const children = this.$refs["content"].children;
    if (children.length === 1) {
      this.height = children[0].offsetHeight;
    }
  }
};
</script>

<style lang="scss">
.foldable-panel {
  padding: 16px;
  background: #fff;
  position: relative;

  .content {
    transition: all 0.3 ease-in-out;
    overflow: hidden;
    height: 40px;

    &.open {
      overflow: visible;
      height: auto;
    }
    display: flex;
    .left {
      flex: 1;
    }
  }
  .ope {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 16px;
    text-align: center;
  }
}
</style>