const brandListEdit = {
    // 初始化状态
    state: {
        brandList: []
    }, // 编写动作
    mutations: {
        BRANDLIST(state, data) {
            state.brandList = data;
        }
    }, // 取值
    getters: {
        brandList: function (state) {
            return state.brandList;
        }
    }, // 创建驱动，可异步
    actions: {
        change2({commit}, data) {
            commit("BRANDLIST", data);
        }
    }
};

export default brandListEdit;
