<template>
  <div class="dsn-form-header">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "DsnFormHeader"
};
</script>

<style lang="scss">
.dsn-form-header {
  padding: 20px 10px;
}
</style>
