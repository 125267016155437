import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      btnList: state => {
        const arr = state["userinfo"]["userinfo"]["buttonPermission"];
        if (arr && arr.length) {
          return arr;
        }
        return [];
      },
      appMenuList: state => {
        const arr = state["userinfo"]["userinfo"]["menuPermission"];
        if (arr && arr.length == 1) {
          return arr[0]["children"];
        }

        return [];
      },
      userInfo: state => {
        const name = state["userinfo"]["userinfo"]["name"];
        const userType = state["userinfo"]["userinfo"]["userType"];
        if (name) {
          return {
            name,
            userType
          };
        }
        return {
          name: "",
          userType: ""
        };
      }
    })
  },
  data() {
    return {};
  },
  methods: {
    // 索引的方法

    messageBox(config) {
      const {
        type: type = "warning",
        title: title = this.$t('commmsg.tip'),
        content: content = "是否删除所选数据",
        confirmButtonText: confirmButtonText = "确定",
        cancelButtonText: cancelButtonText = "取消"
      } = config || {};
      return new Promise((resove, reject) => {
        this.$confirm(content, title, {
          confirmButtonText: confirmButtonText,
          cancelButtonText: cancelButtonText,
          type: type
        })
          .then(() => {
            resove("ok");
          })
          .catch(() => {
            reject("cancle");
          });
      });
    },
    notification() {
      return {
        simple: config => {
          const {
            title: title = this.$t('commmsg.tip'),
            body: body = "",
            type: type = ""
            // icon: icon = "https://placehold.it/48x100"
          } = config || {};
          this.$snotify.simple(body, title, {
            type
            // icon
          });
        },
        info: config => {
          const {
            title: title = this.$t('commmsg.tip'),
            body: body = "",
            type: type = ""
            // icon: icon = "https://placehold.it/48x100"
          } = config || {};
          this.$snotify.info(body, title, {
            type
            // icon
          });
        },
        success: config => {
          const {
            title: title = this.$t('commmsg.tip'),
            body: body = "",
            type: type = ""
            // icon: icon = "https://placehold.it/48x100"
          } = config || {};
          this.$snotify.success(body, title, {
            type
            // icon
          });
        },
        error: config => {
          const {
            title: title = this.$t('commmsg.tip'),
            body: body = "",
            type: type = ""
            // icon: icon = "https://placehold.it/48x100"
          } = config || {};
          this.$snotify.error(body, title, {
            type
            // icon
          });
        },
        warn: config => {
          const { title: title = this.$t('commmsg.tip'), body: body = "", type: type = "" } =
            config || {};
          this.$snotify.warning(body, title, {
            type
          });
        }
      };
    },
    notificationInstance() {
      return this.$snotify;
    }
  }
};
