<template>
  <div class="dns-table-search">
    <div class="header">
      <slot>
        <dns-input v-model="key"></dns-input>
      </slot>
    </div>
    <dsn-table
      :data="data"
      :height="height"
      ref="table"
      @current-change="handleCurrentChange"
      highlight-current-row
      @row-click="handleRowClick"
    >
      <slot></slot>
    </dsn-table>
  </div>
</template>

<script>
export default {
  name: "DsnTableWidthSearch",
  props: {
    data: {
      type: Array,
      default: () => {
        return [];
      }
    },
    height: {
      type: [Number, String],
      required: false
    }
  },
  data() {
    return {
      key: ""
    };
  },
  methods: {
    handleRowClick(row, column, event) {
      this.$emit("row-click", row, column, event);
    },
    handleCurrentChange(currentRow, oldCurrentRow) {
      console.log("aa");
      this.$emit("current-change", currentRow, oldCurrentRow);
    },
    setCurrent(row) {
      this.$refs["table"].setCurrent(row);
    }
  },
  mounted() {},
  created() {}
};
</script>