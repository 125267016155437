<template>
  <el-table-column
    :type="type"
    :label="label"
    :prop="prop"
    :index="index"
    :column-key="columnKey"
    :width="width"
    :min-width="minWidth"
    :fixed="fixed"
    :render-header="renderHeader"
    :sortable="sortable"
    :sort-method="sortMethod"
    :sort-by="sortBy"
    :sort-orders="sortOrders"
    :formatter="formatter"
    :show-overflow-tooltip="showOverflowTooltip"
    :align="align"
    :header-align="headerAlign"
    :class-name="className"
    :label-class-name="labelClassName"
    :selectable="selectable"
    :reserve-selection="reserveSelection"
    :filters="filters"
    :filter-placement="filterPlacement"
    :filter-multiple="filterMultiple"
    :filter-method="filterMethod"
    :filtered-value="filteredValue"
  >
    <template   v-slot:header="{column, $index}">
      <slot  name="header"  :column="column"  :$index="$index" >
      </slot>
    </template>
    <template  slot-scope="{row,column,$index}">
        <slot  :row="row" :column="column" :$index="$index"></slot>
    </template>
    
  </el-table-column>
</template>

<script>
//selection/index/expand
export default {
  name: "dsnTableColumn",
  props: {
    type: {
      type: String,
      required: false
    },
    label: {
      type: [String],
      required: false
    },
    prop: {
      type: [String],
      required: false
    },
    index: {
      type: [Number, Function],
      required:false
    },
    columnKey: {
      type: [String],
      required: false
    },
    width: {
      type: [String],
      required: false
    },
    minWidth: {
      type: [String],
      required: false
    },
    //true, left, right
    fixed: {
      type: [String, Boolean],
      required: false
    },
    renderHeader: {
      type: [Function],
      required: false
    },
    //true, false, 'custom'
    sortable: {
      type: [String, Boolean],
      default: false
    },
    sortMethod: {
      type: [Function],
      required: false
    },
    sortBy: {
      type: [Function, Array, String],
      required: false
    },
    sortOrders: {
      type: [Array],
      default: () => {
        return ["ascending", "descending", null];
      }
    },
    formatter: {
      type: [Function],
      required: false
    },
    showOverflowTooltip: {
      type: Boolean,
      default: false
    },
    align: {
      type: [String],
      default: "left"
    },
    headerAlign: {
      type: [String],
      default: "left"
    },
    className: {
      type: [String],
      required: false
    },
    labelClassName: {
      type: [String],
      required: false
    },
    selectable: {
      type: [Function],
      required: false
    },
    reserveSelection: {
      type: Boolean,
      default: false
    },
    filters: {
      type: Array,
      required: false
    },
    filterPlacement: {
      type: String,
      required: false
    },
    filterMultiple: {
      type: Boolean,
      default: true
    },
    filterMethod: {
      type: Function,
      required: false
    },
    filteredValue: {
      type: Array,
      required: false
    }
  },
  created(){
  }
};
</script>
