<template>
  <!--  重置 -->
  <dsn-button
    size="small"
    type="primary"
    icon="el-icon-upload2"
    :disabled="disabled"
    :loading="loading"
    :round="round"
    :circle="circle"
    :autofocus="autofocus"
    @click="click"
  >
    <slot></slot>
  </dsn-button>
</template>
<script>
export default {
  name: "DsnButtonExport",
  props: {
    // 类型
    // type   primary / success / warning / danger / info / text

    // 是否禁用
    disabled: {
      type: Boolean,
      default: false
    },
    plain: {
      type: Boolean,
      default: false
    },
    round: {
      type: Boolean,
      default: false
    },
    circle: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },

    autofocus: {
      type: Boolean,
      default: false
    },
    nativeType: {
      type: String,
      default: "button"
    }
  },
  methods: {
    click: function() {
      this.$emit("click");
    }
  }
};
</script>
