const hasPermission = {
    install(Vue, options) {
        Vue.directive('has', {
            inserted: (el, binding, vNode) => {
                filterGlobalPermission(el, binding, vNode)
            }
        })
    }
}

export const filterGlobalPermission = (el, binding, vNode) => {
    // console.info(el)
    let permissionList = [];
    let authList = JSON.parse(localStorage.getItem("USER_PERMISSIONS") || null);
    if (authList.length > 0) {
        for (let authListElement of authList) {
            permissionList.push(authListElement)
        }
    }
    if (!permissionList.length) {
        el.parentNode.removeChild(el)
        return;
    }
    let permissions = []
    for (let permissionCode of permissionList) {
        // console.info(permissionCode)
        permissions.push(permissionCode);
    }
    if (!permissions.includes(binding.value)) {
        el.parentNode.removeChild(el)
    }
}

export default hasPermission;
