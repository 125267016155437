import Vue from "vue";
import VueRouter from "vue-router";
import baseRouter from "@/views/base/router/index.js";
import Welcome from "@/views/welcome";
import store from "@/store/";
import {toLogin} from "@/until/action";
import homeindexRouter from "@/views/homeindex/router/index.js";
import equipmentRouter from "@/views/mes/equipment/router/index.js";
import baseSettingRouter from "@/views/mes/basic_settings/router/index.js";
import businessRouter from "@/views/mes/business/router/index.js";


Vue.use(VueRouter);

const routes = [{
    path: "/",
    redirect: "/welcome/",
},
    /**
     * 基础库
     */
    {
        ...baseRouter,
    },
    {
        ...homeindexRouter,
    },
    {
        ...equipmentRouter,
    },
    {
        ...baseSettingRouter,
    }, {
        ...businessRouter,
    },
    {
        path: "/welcome",
        name: "welcome",
        // component: () =>
        //     import (
        //         /* webpackChunkName: "IEwork" */
        //         "@/views//welcome/index.vue"
        //     ),
        component: () =>
            import ( /* webpackChunkName: "layout" */ "../views/layout/"),
        children: [{
            path: "",
            name: "welcomePage",
            meta: {
                title: "首页",
                requiresAuth: true,
            },
            component: Welcome,
        },],
    },
    /**
     *  常用设置
     */
    {
        path: "/login",
        name: "login",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "login" */ "../views/login"),
    },
    {
        path: "*",
        name: "notFound",
        meta: {
            title: "404",
            requiresAuth: false
        },
        component: () =>
            import ( /* webpackChunkName: "login" */ "@/views/layout/not-found.vue")
    }
];
/**
 *   重写 push 方法
 */
const routerPushOrigin = VueRouter.prototype.push; // 原始的路由push 方法
VueRouter.prototype.push = function push(location) {
    return routerPushOrigin
        .call(this, location)
        .catch((error) => console.log(error));
};
/**
 *  push and Clear
 */
VueRouter.prototype.pushAndClear = function (location, {deleteItem}) {
    routerPushOrigin.call(this, location);
    store.commit("POP", {deleteItem, current: {name: ""}}); //先删除当前的对象 返回回到 上一个页面
};
/**
 *  重写go 方法
 */
const routerGoOrigin = VueRouter.prototype.go;
VueRouter.prototype.go = function go(location) {
    return routerGoOrigin.call(this, location);
};
/**
 *  重写go 方法
 */

VueRouter.prototype.goAndClear = function goAndClear(
    location, {deleteItem, current}
) {
    routerGoOrigin.call(this, location);
    store.commit("POP", {deleteItem, current}); //先删除当前的对象 返回回到 上一个页面
};
/**
 *  重写replace
 */
const routerReplaceOrigin = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
    return routerReplaceOrigin.call(this, location);
};
const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});


router.beforeEach((to, from, next) => {
    // let {operateType} = to.query;
    // if (operateType != null && operateType !== '') {
    //     if ("add".toUpperCase() === operateType.toUpperCase()) {
    //         document.title = i18n.t('commbtn.add') + to.meta.title;
    //     }
    //     if ("edit".toUpperCase() === operateType.toUpperCase()) {
    //         document.title = i18n.t('commbtn.edit') + to.meta.title;
    //     }
    // } else {
    //     document.title = to.meta.title;
    // }
    document.title = to.meta.title;
    // console.info(to);
    // console.info(from);
    store.commit("PUSH", {
        name: to.name,
        title: to.meta.title || "",
        path: to.path,
        level: to.meta.level || -1,
        fullPath: to.fullPath,
        titleI18n: to.meta.titleI18n || ""
    });
    let auth = to.matched.some((record) => record.meta.requiresAuth);
    if (auth) {
        const token = Vue.$cookies.get("tpm_token");
        const isLogin = token ? true : false;
        if (!isLogin) {
            // console.log('token  不存在');
            // next({
            //   path: "/login",
            //   query: { redirect: to.fullPath } //把要跳转的地址作为参数传到下一步
            // });
            toLogin(to.fullPath);
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
