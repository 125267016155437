const positionRefEdit = {
    // 初始化状态
    state: {
        positionRefList: []
    },
    // 编写动作
    mutations: {
        POSITIONREFLIST(state, data) {
            state.positionRefList = data;
        }
    },
    // 取值
    getters: {
        materialList: function (state) {
            return state.positionRefList;
        }
    },
    // 创建驱动，可异步
    actions: {
        change2({commit}, data) {
            commit("POSITIONREFLIST", data);
        }
    }
};

export default positionRefEdit;
