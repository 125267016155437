export default {
  path: "/base",
  name: "base",
  meta: {
    title: "基础库",
    requiresAuth: true,
    level: 0,
  },
  component: () => import(/* webpackChunkName: "layout" */ "@/views/layout/"),
  children: [
    // {
    //   path: "base",
    //   name: "base",
    //   meta: {
    //     title: "基础库",
    //     requiresAuth: true,
    //     level: 0,
    //   },
    //   component: () =>
    //     import(/* webpackChunkName: "base" */ "@/views/base/pages/Base.vue"),
    // },
    // {
    //   path: "addBase",
    //   name: "addBase",
    //   meta: {
    //     title: "基础库添加",
    //     requiresAuth: true,
    //   },
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "addBase" */ "@/views/base/pages/AddBase.vue"
    //     ),
    // },
    // {
    //   path: "editBase",
    //   name: "editBase",
    //   meta: {
    //     title: "基础库编辑",
    //     requiresAuth: true,
    //   },
    //   component: () =>
    //     import(
    //       /* webpackChunkName: "editBase" */ "@/views/base/pages/EditBase.vue"
    //     ),
    // },
    //站位按钮分配
    {
      path: "standingButtonDistribute",
      name: "standingButtonDistribute",
      meta: {
        title: "站位按钮分配",
        requiresAuth: true,
        level: 1,
      },
      component: () =>
        import(
          /* webpackChunkName: "standingButtonDistribute" */ "@/views/base/pages/standingButtonDistribute.vue"
        ),
    },
    //站位按钮分配编辑
    {
      path: "standingButtonDistributeEdit",
      name: "standingButtonDistributeEdit",
      meta: {
        title: "站位按钮分配编辑",
        requiresAuth: true,
      },
      component: () =>
        import(
          /* webpackChunkName: "standingButtonDistribute" */ "@/views/base/pages/standingButtonDistributeEdit.vue"
        ),
    },
  ],
};
